import React, { useState, useReducer } from 'react'
import awsconfig from '../aws-exports'; // if you are using Amplify CLI
import Amplify, { Auth } from 'aws-amplify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import {checkUsername} from '../api/aws/amplify_api';

Amplify.configure(awsconfig);

const initialFormState = {
  username: '', password: '', email: '', accessCode: '', confirmationCode: '', formValid: true, confirmValid: true
}

const initialDialogState = {
  openDialog: false, dialogTitle: '', dialogMsg: '', prevFormState: '', dialogType: ''
}


function reducer(state, action) {
  switch(action.type) {
    case 'updateFormState':
        if(action.e.target.name == 'username' || action.e.target.name == 'email'){
          action.e.target.value = action.e.target.value.toLowerCase();
        }
      return {
        ...state, [action.e.target.name]: action.e.target.value
      }
      case 'updateFormStatus':
        return{
          ...state, formValid: action.status
        }
      case 'updateConfirmStatus':
        return{
          ...state, confirmValid: action.status
        }
    case 'updateDialogType':
      return{
        ...state, openDialog: true, dialogTitle: action.title, dialogMsg: action.msg, prevFormState: action.prevFormState, dialogType: action.dialogType
      }
    default:
      return state
  }
}


async function signUp({ username, password, email, formValid }, updateFormType, updateFormState, updateDialogState) {
  if(formValid == true){
    updateFormState({type: 'updateFormStatus', status: false});
        try {
          await Auth.signUp({
            username, password, attributes: { email }
          })
          console.log('sign up success!')
          updateFormType('confirmSignUp')
        } catch (err) {
          console.log('error signing up..', err)
          updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err.message, prevFormState: 'signUp', dialogType: 'info'})
          updateFormState({type: 'updateFormStatus', status: true});
          updateFormType('newDialog');
        }
  }
}

function preSignUp({ username, password, email, accessCode, formValid }, updateFormType, updateFormState, updateDialogState){
  updateFormState({type: 'updateFormStatus', status: false});
  updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Loading...', msg: "Authenticating username and password", prevFormState: 'signIn', dialogType: 'loading'})
      updateFormType('newDialog');
      if(accessCode == 'NSNS1'){
  checkUsername(username).then(resp=>{
    console.log(resp);
    if(resp.success == true){
      signUp({ username, password, email, formValid }, updateFormType, updateFormState, updateDialogState);
    }else{
      console.log('error signing up..')
        updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: resp.data.message, prevFormState: 'signUp', dialogType: 'info'})
        updateFormState({type: 'updateFormStatus', status: true});
        updateFormType('newDialog');
    }
  }).catch(err=>{
    console.log(err);
  });
}else{
  updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: "Invalid Access Code!", prevFormState: 'signUp', dialogType: 'info'})
    updateFormState({type: 'updateFormStatus', status: true});
    updateFormType('newDialog');
}
}

async function confirmSignUp({ username, confirmationCode, confirmValid }, updateFormType, updateFormState, updateDialogState) {
  updateFormState({type: 'updateConfirmStatus', status: false});
  if(confirmValid == true){
    try {
      await Auth.confirmSignUp(username, confirmationCode)
      console.log('confirm sign up success!')
      updateFormType('signIn')
      updateFormState({type: 'updateFormStatus', status: true});
    } catch (err) {
      console.log('error signing up..', err)
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err.message, prevFormState: 'confirmSignUp', dialogType: 'info'})
      updateFormState({type: 'updateConfirmStatus', status: true});
      updateFormType('newDialog')
    }
  }
}

async function signIn({ username, password, formValid }, updateFormType, updateFormState, updateDialogState, callBackFunction) {
  updateFormState({type: 'updateFormStatus', status: false});
  if(formValid == true){
    try {
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Loading...', msg: "Authenticating username and password", prevFormState: 'signIn', dialogType: 'loading'})
      updateFormType('newDialog')
      await Auth.signIn(username, password).then(user=>{
        callBackFunction({user: user});
      })
    } catch (err) {
      console.log('error signing up..', err)
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err.message, prevFormState: 'signIn', dialogType: 'info'})
      updateFormState({type: 'updateFormStatus', status: true});
      updateFormType('newDialog')
    }
  }
}

async function newPassword({ username, confirmationCode, password, formValid }, updateFormType, updateFormState, updateDialogState) {
  if(formValid == true){
    updateFormState({type: 'updateFormStatus', status: false});
    try {
      await Auth.forgotPasswordSubmit(username, confirmationCode, password).then(user=>{
        console.log(user);
        updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Success!', msg: 'New password created, please log in with your new password.', prevFormState: 'signIn', dialogType: 'info'})
        updateFormState({type: 'updateFormStatus', status: true});
        updateFormType('newDialog')
      })
    } catch (err) {
      console.log('error signing up..', err)
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err.message, prevFormState: 'passwordRecover', dialogType: 'info'})
      updateFormState({type: 'updateFormStatus', status: true});
      updateFormType('newDialog')
    }
  }
}

async function resendCode({ username }, updateFormType, updateDialogState) {
    try {
      await Auth.resendSignUp(username).then(user=>{
        console.log('Code Resent!');
        updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Success!', msg: 'Confirmation Code Resent', prevFormState: 'confirmSignUp', dialogType: 'info'})
        updateFormType('newDialog')
      })
    } catch (err) {
      console.log('error sending code..', err)
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err.message, prevFormState: 'confirmSignUp', dialogType: 'info'})
      updateFormType('newDialog')
  }
}

async function resetPassword({ username }, updateFormType, updateDialogState) {
  try {
    await Auth.forgotPassword(username).then(user=>{
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Success!', msg: 'Check your email for a new verification code.', prevFormState: 'passwordRecover', dialogType: 'info'})
      updateFormType('newDialog')
    })
  } catch (err) {
    console.log('error sending code..', err)
    if(err.message){
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err.message, prevFormState: 'signIn', dialogType: 'info'})
    }else{
      updateDialogState({type: 'updateDialogType', openDialog: true, title: 'Error!', msg: err, prevFormState: 'signIn', dialogType: 'info'})
    }
    updateFormType('newDialog')
}
}

function showDialog(dialogState, updateFormType, updateFormState, updateDialogState){
  updateFormType(dialogState.prevFormState);
  updateDialogState({type: 'updateDialogType', openDialog: false, title: '', msg: '', prevFormState: '', dialogType: ''});  
}

export default function Form(props) {
  const [formType, updateFormType] = useState('signIn')
  const [formState, updateFormState] = useReducer(reducer, initialFormState)
  const [dialogState, updateDialogState] = useReducer(reducer, initialDialogState)
  function renderForm() {
    switch(formType) {
      case 'signUp':
        return (
          <SignUp
            signUp={() => preSignUp(formState, updateFormType, updateFormState, updateDialogState)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
          />
        )
      case 'confirmSignUp':
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType, updateFormState, updateDialogState)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
          />
        )
      case 'signIn':
        return (
          <SignIn
            signIn={() => signIn(formState, updateFormType, updateFormState, updateDialogState, props.signInCallback)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
            resetPassword={() => resetPassword(formState, updateFormType, updateDialogState)}
          />
        )
        case 'passwordRecover':
        return (
          <PasswordRecover
            submitNewPassword={() => newPassword(formState, updateFormType, updateFormState, updateDialogState)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
          />
        )
        case 'newDialog':
        return (
          <NewDialog
            showDialog={() => showDialog(dialogState, updateFormType, updateFormState, updateDialogState)} newDialog={dialogState}
          />
        )
      default:
        return null
    }
  }
  

  return (
    <div>
      <div>
        {renderForm(formState)}
      </div>
      {
        formType === 'signUp' && (
          <p style={styles.footer}>
            Already have an account? <span
              style={styles.anchor}
              onClick={() => updateFormType('signIn')}
            >Sign In</span>
          </p>
        )
      }
      {
        formType === 'signIn' && (
          <p style={styles.footer}>
            Need an account? <span
              style={styles.anchor}
              onClick={() => updateFormType('signUp')}
            >Sign Up</span>
          </p>
        )
      }
      {
        formType === 'confirmSignUp' && (
          <p style={styles.footer}>
            Resend <span
              style={styles.anchor}
              onClick={() => resendCode(formState, updateFormType, updateDialogState)}
            >confirmation code</span>
          </p>
        )
      }
    </div>
  )
}

function SignUp(props) {
  return (
    <div style={styles.container}>
    <form style={styles.form}>
      <div>
        Sign Up!
      </div>
      <div>
        <input 
          name='username'
          onChange={e => {e.persist();props.updateFormState(e)}}
          style={styles.input}
          placeholder='email'
        />
      </div>
      <div>
      <input
        type='password'
        name='password'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
        placeholder='password'
      />
      </div>
      <div>
      <input
        name='accessCode'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
        placeholder='Access Code'
      />
      </div>
      <div>
      <button type="button" onClick={props.signUp} style={styles.button}>
        Sign Up
      </button>
      </div>
      </form>
    </div>
  )
}

function SignIn(props) {
  return (
    <div style={styles.container}>
    <form style={styles.form}>
    <div>
        Sign In!
      </div>
      <div>
      <input 
        name='username'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
        placeholder='Email'
      />
      </div>
      <div>
      <input
        type='password'
        name='password'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
        placeholder='password'
      />
      </div>
      <div>Forgot your Password? <span
              style={styles.anchor}
              onClick={props.resetPassword}
            >Reset Password</span></div>
      <div>
      <button type="button" style={styles.button} onClick={props.signIn}>
        Sign In
      </button>
      </div>
      </form>
    </div>
  )
}

function PasswordRecover(props) {
  return (
    <div style={styles.container}>
    <form style={styles.form}>
      <div>
      <input
        name='confirmationCode'
        placeholder='Confirmation Code'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
      />
      </div>
      <div>
      <input
        type='password'
        name='password'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
        placeholder='new password'
      />
      </div>
      <div>
      <button type="button" style={styles.button} onClick={props.submitNewPassword}>
        Submit New Password
      </button>
      </div>
      </form>
    </div>
  )
}

function ConfirmSignUp(props) {
  return (
    <div style={styles.container}>
    <form style={styles.form}>
    <div>
        Check your email for a confirmation code and enter it below!
      </div>
      <input
        name='confirmationCode'
        placeholder='Confirmation Code'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
      />
      <button type="button" onClick={props.confirmSignUp} style={styles.button}>
        Confirm Sign Up
      </button>
      </form>
    </div>
  )
}

function NewDialog(props) {
  return (
    <div style={styles.container}>
      {props.newDialog.dialogType == 'info' ? 
      <Dialog
        open={props.newDialog.openDialog}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">{props.newDialog.dialogTitle}</DialogTitle>
        <DialogContent>
        <DialogContentText>
                    {props.newDialog.dialogMsg}      
        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button type="button" onClick={props.showDialog} style={styles.button}>
                            Ok
            </button>
                    </DialogActions>
                </Dialog>
                : <Dialog
                open={props.newDialog.openDialog}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">{props.newDialog.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                            <div>{props.newDialog.dialogMsg}</div>
                            <div>
                            <LinearProgress />
                            </div>
                </DialogContentText>
                            </DialogContent>
                        </Dialog>}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form:{
    backgroundColor: 'rgba(255,255,255,0.8)',
    padding: "25px",
    borderRadius: "3px"
  },
  input: {
    height: 45,
    marginTop: 8,
    width: 300,
    maxWidth: 300,
    padding: '0px 8px',
    fontSize: 16,
    outline: 'none',
    border: 'none',
    borderBottom: '2px solid rgba(0, 0, 0, .3)'
  },
  button: {
    backgroundColor: 'rgb(77,198,231)',
    color: 'white',
    width: 316,
    height: 45,
    marginTop: 10,
    fontWeight: '600',
    fontSize: 14,
    cursor: 'pointer',
    border:'none',
    outline: 'none',
    borderRadius: 3,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, .3)',
  },
  footer: {
    fontWeight: '600',
    padding: '0px 25px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.6)'
  },
  anchor: {
    color: '#006bfc',
    cursor: 'pointer'
  }
}