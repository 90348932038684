import React, {Component} from 'react';
import awsconfig from './../aws-exports'; // if you are using Amplify CLI
import Amplify, { API } from 'aws-amplify';
import {getUser} from '../api/aws/amplify_api';
import { connect } from 'react-redux';
import { verifyUser} from '../functions/auth_functions';
import {setUserToReduxState, logoutUserFromReduxState} from '../actions/auth_actions';
// import {CustomSignUp} from '../components/sign_up';
import Form from '../functions/authForm';
import NSNHeader from "../components/header";
// import '../style/clock_output.css';
import NinjaMasterAPI from "../components/NinjaMasterAPI";
import queryString from 'query-string';
import Timer from '../components/Timer';
import '../style/stopwatch.css';
import '../style/ninja_master.css';
import NSC_Logo from '../static/Logo_Clock_Graphic.png';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';

Amplify.configure(awsconfig);

// function getDefaultState() {
//   return {
//     isRunning: false,
//     time: 0,
//     timeList: [],
//     start: 0,
//     stopTime: 0,
//     restartTime: 0,
//     adjustedTime: 0,
//     dataFromServer: '',
//     ws: null,
//     timerRef: null,
//     athlete: '',
//     curr_obstacle: 1,
//     total_obstacles: 1,
//     fails: 0,
//     obstacle_result: 'clear',
//     event_id: null,
//     stat_ind: 0,
//     ro_ind: 0,
//     course_desc: '',
//     obstacle: '',
//     reset_time: 0,
//     fail: false,
//     fail_cnt: 0,
//     placement: '',
//     timeDelay: 0
//   }
// }

class NinjaMaster extends Component {
    constructor(props) {
        super(props);
        this.timerRef = null;
    }

    state={
      isRunning: false,
      time: 0,
      timeList: [],
      start: 0,
      stopTime: 0,
      restartTime: 0,
      adjustedTime: 0,
      dataFromServer: '',
      ws: null,
      timerRef: null,
      athlete: '',
      curr_obstacle: 1,
      total_obstacles: 1,
      fails: 0,
      obstacle_result: 'clear',
      event_id: null,
      stat_ind: 0,
      ro_ind: 0,
      course_desc: '',
      obstacle: '',
      reset_time: 0,
      fail: false,
      fail_cnt: 0,
      placement: '',
      timeDelay: 0,
      actionCableUrl: "wss://ninjamastersoftware.com/cable"
    }


    start = (start, res, adj, ath, place) => {
      if (this.state.stopTime != 0) {
        this.setState({ start: start, restartTime: res, adjustedTime: adj, athlete: ath, placement: place, time: 0 });
      } else {
        this.setState({ start: start, athlete: ath , placement: place, time: 0 });
      }
      if (this.state.isRunning == false) {
        this.setState({
          isRunning: true, athlete: ath, placement: place, time: 0, start: start
        }, () => {
          this.timerRef = setInterval(
            () => {
              var delta = Date.now() - this.state.start - (this.state.adjustedTime); // milliseconds elapsed since start
              this.updateTimer(delta - this.state.timeDelay);
            }, 10
          )
        });
      }
    }

    stop = (stop, ath, place) => {
      // console.log('stop function');
      this.setState({
        isRunning: false,
        stopTime: stop,
        athlete: ath, 
        placement: place
      }, () => {
        clearInterval(this.timerRef);
        var delta = this.state.stopTime - this.state.start - (this.state.adjustedTime);
        this.updateTimer(delta);
      });
    }

    updateTimer(extraTime) {
      // console.log(this.props.live_clock);
      const { time } = this.state;
      if (this.state.isRunning == true) {
  
      } else {
        this.timerRef = null;
      }
      this.setState({ time: extraTime });
    }

    convertDatetoMill = (itm) =>{
      var millDate = new Date(itm);
      var mill = millDate.getTime();
      // console.log("Mill Date");
      // console.log(millDate);
      // console.log("Mill Time");
      // console.log(mill);
      return mill;
    }

    // startClock(){
    //   var startStr = "2023-07-21T18:34:50.632Z";
    //   var test = Date.now();
    //   console.log("Date is" + test);
    //   this.convertDatetoMill(startStr);
    //   this.start(Date.now(), 0,0, 'Chris DiGangi');
    // }

    // stopClock(){
    //   this.stop(Date.now());
    // }

    receivedMessage(message){
      this.graphicUpdate(message);
    }

    placementConcat(place){
      var str = '';
      if(place % 100 > 10 && place % 100 < 20){
        str = 'TH';
      }else{
      switch(place % 10){
        case 1:
          str = 'ST';
          break;
        case 2:
          str = 'ND';
          break;
        case 3:
          str = 'RD';
          break;
          default:
            str = 'TH';
      }
    }
    return (place + str + " PLACE");
    }

    graphicUpdate = (message) =>{
      console.log(message);
      var parseData = message.started.filter(obj=>obj.finished == false);
      // console.log(parseData);
      if(parseData.length < 1 && this.state.athlete != ""){
        parseData = message.started.filter(obj=>obj.contestant_name == this.state.athlete);
      }
        // this.setState({athlete: parseData[0].contestant_name, placement: parseData[0].placement, start: parseData[0].start, stop: parseData[0].stop});
  
      console.log(parseData);
      // if (parseData.clock_status == "athlete_submitted" || parseData.clock_status == "warming") {
          
          // console.log(this);
          var clock_status = '';
          // console.log("Clock Status is null");
          if(parseData.length > 0){
          if(parseData[0].stop==null || parseData[0].stop=='' || parseData[0].stop==undefined){
            // console.log("Stop is null");
            if(parseData[0].start != null && parseData[0].start!=='' && parseData[0].start!==undefined){
              // console.log("Clock should run");
              if(this.state.isRunning == true){
                // console.log("IsRunning True");
                clock_status = "running";
              }else{
                // console.log("start True");
                clock_status = "start";
              }
            }
          }else{
            // console.log("stop True");
            clock_status = "stop";
          }

console.log(clock_status);

          switch (clock_status) {
            case "start":
              // console.log("start");
              this.start(this.convertDatetoMill(parseData[0].start), 0, 0, parseData[0].contestant_name, parseData[0].placement);
              break;
            case "stop":
              // console.log("stop");
              // setTimeout(() => {
                this.stop(this.convertDatetoMill(parseData[0].stop), parseData[0].contestant_name, parseData[0].placement);
              // }, this.state.timeDelay);
              break;
            case "running":
              // console.log("running");
              if(this.state.isRunning == false){
                this.start(this.convertDatetoMill(parseData[0].startTime), 0, 0);
              }
              // setTimeout(() => {
                this.setState({ athlete: parseData[0].contestant_name, placement: parseData[0].placement });
              // }, this.state.timeDelay);
  
              break;

              default:
                // console.log("default");
                this.setState({
                  athlete: parseData[0].contestant_name, isRunning: false,
                  time: 0,
                  start: 0,
                  stopTime: 0,
                  restartTime: 0,
                  adjustedTime: 0,
                  dataFromServer: '',
                  curr_obstacle: 1,
                  fails: 0,
                  fail: false,
                  obstacle_result: "clear",
                  fail_cnt: 0
                });
          }
        }
    };


componentDidMount() {
    // console.log(this);
    var queryObj = queryString.parse(this.props.location.search);
    
        this.setState({ channel: queryObj.channel, courseId: queryObj.courseId, waveId: queryObj.waveId });
}
    render() {
      
      const { isRunning, time, timeList } = this.state;
      return (
          <div className="">
             {/* <ActionCableProvider url={this.state.actionCableUrl}>
            <ActionCableConsumer channel={({channel: this.state.channel, course_id: this.state.courseId, wave_id: this.state.waveId})} onReceived={this.graphicUpdate} onConnected={this.connectedFunction.bind(this)}></ActionCableConsumer>
        </ActionCableProvider> */}
            <NinjaMasterAPI updateGraphics={this.receivedMessage.bind(this)} getMsgParam="warming" courseId={this.state.courseId} waveId={this.state.waveId} channel={this.state.channel} actionCableUrl="wss://ninjamastersoftware.com/cable"></NinjaMasterAPI>
            <NSNHeader title='NSN Scoreboard' link='' linkTitle=''></NSNHeader>
            <div className="pageContainer" style={{overflowX: 'hidden', marginLeft: "0px", marginRight: "0px"}}>

              {/* <div>{this.state.athlete}</div>
              <div>{this.state.placement}</div>
              <div className="V2Timer"><Timer time={time} displayType={'live'} /></div>

              <div>
            <button className='nsnBtn' onClick={e => this.startClock()}>Start Clock</button>
            </div>

            <div>
            <button className='nsnBtn' onClick={e => this.stopClock()}>Stop Clock</button>
            </div> */}


        <div className="NMClockBackground">
        <div className="NMPlacementBox">
          <div>CURRENT</div>
          <div>{this.state.placement !='' ? this.placementConcat(this.state.placement) : null}</div>
        </div>
        <div className="NMAthleteBox">{this.state.athlete ? this.state.athlete.slice(0, 18).toUpperCase(): null}</div>
        <div className="NMClockBox"><Timer time={time} displayType={'live'} /></div>

        </div>

        {/* <div className="BOCClockBackground">
        <div className="BOCPlacementBox">
          <div>CURRENT</div>
          <div>{this.state.placement !='' ? this.placementConcat(this.state.placement) : null}</div>
        </div>
        <div className="BOCAthleteBox">{this.state.athlete ? this.state.athlete.slice(0, 18).toUpperCase(): null}</div>
        <div className="BOCClockBox"><Timer time={time} displayType={'live'} /></div>

        </div> */}


            </div>
          </div>
      );
    }
  }

export default NinjaMaster;
