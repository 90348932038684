import Amplify, { Auth, API } from 'aws-amplify';
import aws_exports from '../.././aws-exports';

Amplify.configure(aws_exports);

export function getUser(){
    const p = new Promise((res,reject)=>{
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => res(user))
        .catch(err => reject(err));
    })
    return p;
}

export function checkUsername(username){
    
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
         let path = '/user/check_username_exists';
        let myInit = {
            body: {username : username}
        }
        API.post(apiName, path, myInit).then(response => {
            res(response);
        }).catch(error => {
            reject(error.response);
        });
    })
    return p;
}

// export function createNewCustomer(sub, email){
    
//     const p = new Promise((res,reject)=>{
//         let apiName = 'uncAPIamplify';
//         let path = '/api/create_new_customer';
//         let myInit = {
//             body: {user_id : sub, email: email}
//         }
//         API.post(apiName, path, myInit).then(response => {
//             res(response);
//         }).catch(error => {
//             reject(error.response);
//         });
//     })
//     return p;
// }

// export function getStripeInfo(id){
    
//     const p = new Promise((res,reject)=>{
//             let apiName = 'uncAPIamplify';
//             let path = '/api/get_stripe_info';
//             let myInit = {
//                 body: {stripe_id : id}
//             }
//             API.post(apiName, path, myInit).then(response => {
//                 res(response);
//             }).catch(error => {
//                 reject(error.response);
//             });
//     })
//     return p;
// }

// export function updateCustomerPayment(token, stripe_id){
    
//     const p = new Promise((res,reject)=>{
//         let apiName = 'uncAPIamplify';
//         let path = '/api/update_customer_payment';
//         let myInit = {
//             body: {tok_id : token, stripe_id: stripe_id}
//         }
//         API.post(apiName, path, myInit).then(response => {
//             res(response);
//         }).catch(error => {
//             reject(error.response);
//         });
//     })
//     return p;
// }

// export function addSubscription(stripe_id){
    
//     const p = new Promise((res,reject)=>{
//         let apiName = 'uncAPIamplify';
//         let path = '/api/add_subscription';
//         let myInit = {
//             body: {stripe_id: stripe_id}
//         }
//         API.post(apiName, path, myInit).then(response => {
//             res(response);
//         }).catch(error => {
//             reject(error.response);
//         });
//     })
//     return p;
// }