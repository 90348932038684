import React, {Component} from 'react';
import NSNHeader from "../components/header";
import NSNFooter from "../components/footer";
import '../style/main.css';
import { connect } from 'react-redux';
import {setUserToReduxState, logoutUserFromReduxState, updateUserReduxState} from '../actions/auth_actions';
import loadingImg from '../static/NSN_Small_Logo.png';



class AdminPage extends Component {

  state={
    loading: true
  }

  goToPage(str){
    this.props.history.push('/' + str);
}

  componentDidMount(){
    // console.log(this)
    this.setState({loading: false});
  }



    render() {
      if(this.state.loading == true){
        return (<div className="mainDiv" style={{height: "100vh"}}>
            <NSNHeader title='' link='' linkTitle=''></NSNHeader>
            <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                <div className="pulseImg">
                <img src={loadingImg} width="200px" height="auto" />
                </div>
                </div>
                </div>
        )
    }else{
      return (
          <div className="mainDiv">
            <NSNHeader title='' link='' linkTitle=''></NSNHeader>
            <div className="pageContainer">
            <h1 style={{color: "rgb(148,194,228)"}}>Welcome to the Admin Page!</h1>

                <div style={{color: "#dadada", fontSize: "18px"}}>
                Please select an option:
                </div>

                <div className="" style={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    {/* <div className="choiceBox" onClick={e=>this.goToPage('add_competitors')}>Add Competitors</div> */}
                    <div className="darkChoiceBox" onClick={e=>this.goToPage('results_entry')}>Enter Results</div>
                    <div className="darkChoiceBox" onClick={e=>this.goToPage('create_competition')}>Create Competition</div>
                    <div className="darkChoiceBox" onClick={e=>this.goToPage('create_course')}>Create Course</div>
                    {/* <div className="choiceBox" onClick={e=>this.goToPage('update_season_standings')}>Update Season Standings</div> */}
                    {/* <div className="choiceBox" onClick={e=>this.goToPage('course_points')}>View Course Point Breakdown</div> */}
                    {/* <div className="choiceBox" onClick={e=>this.goToPage('course_setup')}>View Course Setup Notes</div> */}
                </div>

            </div>
              <NSNFooter title='' link='' linkTitle=''></NSNFooter>
          </div>
      );
              }
    }
  }


  const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
    setUserToReduxState,
    logoutUserFromReduxState,
    updateUserReduxState
   };

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
