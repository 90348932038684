import React, { Component } from 'react';
import '../style/stopwatch.css';

import Timer         from './Timer';
import Controls      from './Controls';
import LapTimeList   from './LapTimeList';
import { Column, Row } from 'simple-flexbox';
import timeFormat from '../functions/timeFormat';
import ResetTimer from '../components/ResetTimer';


function getDefaultState() {
  return {
    isRunning : false,
    time      : 0,
    timeList  : [],
    choices: [],
    start: 0,
    stopTime: 0,
    restartTime: 0,
    adjustedTime: 0,
    ws: null,
    fail: false,
    failCnt: 0
    
  }
}

class Stopwatch extends Component {

  constructor( props ) {
    super(props);
    this.state    = getDefaultState();
    this.timerRef = null;
  }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
   connect = (compId) => {
       var ws = new WebSocket("wss://7o5i09cjfa.execute-api.us-east-2.amazonaws.com/production?comp_id=" + compId);
       let that = this; // cache the this
       var connectInterval;
  
       // websocket onopen event listener
       ws.onopen = () => {
           console.log("connected websocket main component");
  
           this.setState({ ws: ws });
           var wsObj = {uid: "0", clock_status: "warming", event_id: this.props.event_id, athlete: this.props.athlete_name, athlete_id: this.props.athlete_id, gender: this.props.gender, obstacle_result: '', time: "00:00.00", current_obstacle: this.props.obstacleInd, total_obstacles: this.props.obstacleCnt, result_table: this.props.result_table, reset_time: this.props.reset_time}
           this.sendMessage({"action": "clockUpdate", "sub_action": "warming", "compId": this.props.event_id, "message": JSON.stringify(wsObj) }); //send to websocket if live
          
  
           that.timeout = 250; // reset timer to 250 on open of websocket connection 
           clearTimeout(connectInterval); // clear Interval on on open of websocket connection
       };
  
       // websocket onclose event listener
       ws.onclose = e => {
        if(e.type !== 'close'){
           console.log(
               `Socket is closed. Reconnect will be attempted in ${Math.min(
                   10000 / 1000,
                   (that.timeout + that.timeout) / 1000
               )} second.`,
               e.reason
           );
  
           that.timeout = that.timeout + that.timeout; //increment retry interval
           connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
               }
       };
  
       // websocket onerror event listener
       ws.onerror = err => {
           console.error(
               "Socket encountered error: ",
               err.message,
               "Closing socket"
           );
  
           ws.close();
       };
   };
  
  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
   check = () => {
       const { ws } = this.state;
       if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
   };

   sendMessage=(data)=>{
     const {ws} = this.state // websocket instance passed as props to the child component.
     // console.log(data);
     this.check();
     try {
       console.log('StopWatch Sending Message...')
         ws.send(JSON.stringify(data)) //send data to the server
     } catch (error) {
         console.log(error) // catch error
     }
 }

 closeSocket=()=>{
   const {ws} = this.state // websocket instance passed as props to the child component.
   // console.log(data);
   try {
       ws.close() //send data to the server
   } catch (error) {
       console.log(error) // catch error
   }
 }


  updateTimer(extraTime) {
    // console.log(this.props.live_clock);
    const { time } = this.state;
    // this.setState({ time : time + extraTime });
    this.setState({ time : extraTime });
  }


//   setInterval(function() {
//       var delta = Date.now() - start; // milliseconds elapsed since start
//       …
//       output(Math.floor(delta / 1000)); // in seconds
//       // alternatively just show wall clock time:
//       output(new Date().toUTCString());
//   }, 1000); // update about every second

  start() {
    if(this.state.stopTime != 0){
        this.setState({restartTime: Date.now(), adjustedTime: this.state.adjustedTime + (Date.now() - this.state.stopTime)});
    }else{
        this.setState({start: Date.now()});
    }
    
    this.setState({
      isRunning : true 
    }, () => {
      this.timerRef = setInterval(
        () => {
            var delta = Date.now() - this.state.start - (this.state.adjustedTime); // milliseconds elapsed since start
            this.updateTimer(delta);
            }, 10
      );
       if(this.props.live_clock == "true"){
         var wsObj = {uid: this.props.athlete_id + "" + this.props.obstacleInd, clock_status: "start", event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: "", time: "00:00.00", current_obstacle: this.props.obstacleInd + 1, total_obstacles: this.props.obstacleCnt, startTime: this.state.start, restartTime: this.state.restartTime, adjustedTime: this.state.adjustedTime}
         console.log(wsObj);
         this.sendMessage({"action": "clockUpdate", "sub_action": "start", "compId": this.props.event_id, "message": JSON.stringify(wsObj) }); //send to websocket if live
       }
    });
  }

  stop(val, returnTime) {
    this.setState({
      isRunning : false,
      stopTime: Date.now()
    }, () => {
      clearInterval(this.timerRef);
         if(this.props.live_clock == "true"){
           var clearCheck;
           var curr_obstacle;
           var fails = this.props.total_fails();
           if(val.value == 0){
             clearCheck = 'fail';
             curr_obstacle = this.props.obstacleInd + 1;
           }else{
             clearCheck = 'clear';
             curr_obstacle = this.props.obstacleInd + 2;
           }
           var wsObj = {uid: this.props.athlete_id + "" + (this.props.obstacleInd + 1), clock_status: "stop", event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: clearCheck, time: timeFormat( returnTime, 'display' ), total_obstacles: this.props.obstacleCnt, stopTime: this.state.stopTime, fails: fails}
           this.sendMessage({"action": "clockUpdate", "sub_action": "stop", "compId": this.props.event_id, "message": JSON.stringify(wsObj) }); //send to websocket if live
           // this.closeSocket();
           var delta = this.state.stopTime - this.state.start- (this.state.adjustedTime);
           this.updateTimer(delta);
         }
    });
  }

  addObstacleTime(val) {
    console.log(val);
    var returnTime = this.state.time;
    this.props.obstacleScore(returnTime, val);
    var fails = this.props.total_fails();
    console.log(fails);
    var curr_obstacle  = this.props.obstacleInd + 2;
    var obs_name = this.props.obstacleInd + 1;
    var fail = false;
      if(val.value ==0){
        if(this.state.failCnt + 1 > 2){
          this.stop(val, returnTime);
        }
        this.setState({fail: true, failCnt: this.state.failCnt + 1});
        fail = true;
        curr_obstacle = this.props.obstacleInd + 1;
        obs_name = this.props.obstacleInd;
      }else{
        this.setState({failCnt: 0});
      }
      if(this.props.obstacleInd == (this.props.obstacleCnt-1) && val.value > 0){
        var obs_name = this.props.obstacleInd;
         this.stop(val, returnTime);
      }
      if(this.props.live_clock == "true"){
        var wsObj = {uid: this.props.athlete_id + '' + (this.props.obstacleInd + 1), clock_status: 'running', event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: 'clear', time: timeFormat( returnTime, 'display' ), current_obstacle: curr_obstacle, total_obstacles: this.props.obstacleCnt, fails: fails, fail: fail, obstacle: this.props.obstacleArr[obs_name].obstacle, startTime: this.state.start, restartTime: this.state.restartTime, adjustedTime: this.state.adjustedTime}
        this.sendMessage({"action": "clockUpdate", "sub_action": "running", "compId": this.props.event_id, "message": JSON.stringify(wsObj) }); //send to websocket if live
      }
  }

  hideReset = () =>{
    this.setState({fail: false});
  }

  componentDidMount(){
     if(this.props.live_clock == "true"){
       this.connect(this.props.event_id);
       window.addEventListener('beforeunload', (event) => {
        console.log('Unloading...');
        // Cancel the event as stated by the standard.
        event.preventDefault();
        this.closeSocket();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
        //this.props.apiCall();
      });
     }
     console.log(this);
  }

  componentWillUnmount(){
    this.closeSocket();
  }

  render() {

    const { isRunning, time, timeList } = this.state;

    return (
      <div className="Stopwatch">

      <div className="timerBox">
        <div style={{fontSize: '24px', fontWeight: 'bold'}}>Timer</div>

        <div style={{fontSize: "40px"}}><Timer time={ time } /></div>

      {this.state.fail && this.props.reset_time > 0 && <ResetTimer resetTimerStop={this.hideReset} timeRef={this.props.reset_time} cancelReset={this.hideReset}/>}

        </div>

        <Controls
          isRunning={ isRunning } 
          start={ () => this.start() }
          stop={ () => this.stop(0, '') }
        />

        { isRunning && !this.state.fail ?
          <div className="SWInnerBox">
          <div  className="SWBoxTitle">{this.props.obstacleInd + 1}. {this.props.obstacle.obstacle}</div>
          <Row horizontal="center" wrap>
          {this.props.choices.map((item, index) => {
                return <div className={item.selected == true && item.value == 1 ? "SWBoxContentClear activeBox" : item.selected == false && item.value == 1 ? "SWBoxContentClear" : item.selected == true && item.value == 0 ? "SWBoxContentFail activeBox" : "SWBoxContentFail"} onClick={e=> this.addObstacleTime(item) }>{item.value == 1 ? <span>Clear</span>:<span>Fail</span>}</div>
            })}
            </Row>
          </div>
          : null }  

      </div>
    );
  }
}

export default Stopwatch;