import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store';
import ScrollToTop from 'react-router-scroll-top';
import PrivateRoute from './components/private_route';

import Home from './pages/home';
import SignInPage from './pages/SignIn';
import AdminPage from './pages/admin';
import CreateCourse from './pages/create_course';
import CreateCompetition from './pages/create_competition';
import ResultsEntry from './pages/results_entry';
import GraphicLeaderboard from './pages/graphic_leaderboard';
import PublicLeaderboard from './pages/public_leaderboard';
import LiveGraphics from './pages/live_graphics';
import MiniLeaderboards from './pages/mini_leaderboards';
import PostRunGraphics from './pages/post_run_score';
import AthleteHistoryPage from './pages/athlete_history';
import BottomLiveLBPage from './pages/bottom_live_leaderboard';
import NSCAthleteRanking from './pages/nsc_athlete_ranking';
import RulesPage from './pages/rules';
import YouthChampionshipPage from './pages/youth_championship';
import VideoPage from './pages/VideoPage';
import Videos from './pages/video_dashboard';
import VideoCollection from './pages/video_collection';
import CompRegistration from './pages/comp_registration';
import AthleteBio from './pages/athlete_bio';
import Sponsors from './pages/sponsors';

import NinjaMaster from './pages/ninja_master_api';



// Format Code Shift + Alt + F

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
  <BrowserRouter>
  <ScrollToTop>
            <Switch>
                <Route exact path='/' component={Home}></Route>
                <Route path='/sign_in' component={SignInPage}></Route>
                <PrivateRoute path='/admin' component={AdminPage}></PrivateRoute>
                <PrivateRoute path='/create_course' component={CreateCourse}></PrivateRoute>
                <PrivateRoute path='/create_competition' component={CreateCompetition}></PrivateRoute>
                <PrivateRoute path='/results_entry' component={ResultsEntry}></PrivateRoute>
                <Route path='/graphic_leaderboard' component={GraphicLeaderboard}></Route>
                <Route path='/public_leaderboard' component={PublicLeaderboard}></Route>
                <Route path='/live_graphics' component={LiveGraphics}></Route>
                <Route path='/mini_leaderboards' component={MiniLeaderboards}></Route>
                <Route path='/post_run_score' component={PostRunGraphics}></Route>
                <Route path='/athlete_history' component={AthleteHistoryPage}></Route>
                <Route path='/bottom_live_leaderboard' component={BottomLiveLBPage}></Route>
                <Route path='/nsc_athlete_ranking' component={NSCAthleteRanking}></Route>
                <Route path='/rules' component={RulesPage}></Route>
                <Route path='/youth_championship' component={YouthChampionshipPage}></Route>
                <Route path='/video_page' component={VideoPage}></Route>
                <Route path='/videos' component={Videos}></Route>
                <Route path='/video_collection' component={VideoCollection}></Route>
                <Route path='/comp_registration' component={CompRegistration}></Route>
                <Route path='/sponsors' component={Sponsors}></Route>
                <Route path='/ninja_master_api' component={NinjaMaster}></Route>
                <Route path='/athlete_bio' component={AthleteBio}></Route>
            </Switch>
            </ScrollToTop>
            </BrowserRouter>
            </PersistGate>
            </Provider>
          ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
