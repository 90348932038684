import React, { Component } from 'react';
import '../style/main.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import Registration from "../components/Registration";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../style/ath_bio.css';
import queryString from 'query-string';
import { getAthleteBio } from '../api/api';
import RunOrderBanner from "../components/run_order_banner";





library.add(faUser, faCaretDown, faCaretUp);

class AthleteBio extends Component {

    state = {
        comp_id: '',
        athlete_id: '',
        athlete_arr: [],
        all_comps: [],
        top_5_comps: [],
        recent_comps: [],
        stats: {},
        course_type: ''
    }

    

    componentDidMount() {
        console.log(this);
        var queryObj = queryString.parse(this.props.location.search);
        // var liveCheck = false; Add this in if ever want to add websocket to this page
        // if (queryObj.live_clock) {
        // liveCheck = queryObj.live_clock;
        // }
        // var query_comp = queryObj.comp_id || '';
        var query_athlete_id = queryObj.athlete_id || '';
        var query_comp_id = queryObj.comp_id || '';
        var query_course_type = queryObj.course_type || '';
        getAthleteBio(query_athlete_id).then(resp => {
            console.log(resp);
            var topFive = resp.all_comps.toSorted((a, b) => a.athlete_rank - b.athlete_rank).slice(0,5);
            var recentComps = resp.all_comps.toSorted((a, b) => b.comp_id - a.comp_id).slice(0,5);
            this.setState({ athlete_id: query_athlete_id, comp_id: query_comp_id, course_type: query_course_type, athlete_arr: resp.athlete, all_comps: resp.all_comps, stats: resp.stats[0], top_5_comps: topFive, recent_comps: recentComps });
        });
    }

    getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);


    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>

                <RunOrderBanner comp_id={this.state.comp_id} course_type={this.state.course_type} ></RunOrderBanner>

             {this.state.stats != undefined && this.state.stats != null ?
             <div>
                <div className="athSummaryContainer">
                    {this.state.athlete_arr.length > 0 ?
                        <div className="athFlexCont">

                            <div className="athCard" style={{backgroundImage: "url('https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/" + this.state.athlete_arr[0].first_name + '_' + this.state.athlete_arr[0].last_name + ".jpg'), url('https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/NSC.jpg')"}}>
                            {/* <div className="athCard"> */}
                                {/* Athlete Card Placeholder */}
                                {/* <img className="" src="https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/Tyler_Smith.jpg" alt="actionPhoto" width="auto" height="auto"/> */}
                            </div>

                            <div className="athInfo">
                                <div className="athName">{this.state.athlete_arr[0].first_name + ' ' + this.state.athlete_arr[0].last_name} </div>
                                <div className="athSubInfo">NSR Rank: {this.state.stats.power_rank}</div>
                                <div className="athSubInfo">Age: { this.state.athlete_arr[0].date_of_birth != null ? this.getAge(this.state.athlete_arr[0].date_of_birth) : <span></span>}</div>
                                <div className="athSubInfo">Biography: {this.state.athlete_arr[0].biography}</div>
                                <div className="athSubInfo">Home Gym: {this.state.athlete_arr[0].home_gym}</div>
                                <div className="athSubInfo">NSC Seasons: {this.state.stats.season_count}</div>
                                <div className="athSubInfo">Fun Fact: {this.state.athlete_arr[0].fun_fact}</div>
                                <div className="athSubInfo">Years of Training: {this.state.athlete_arr[0].years_of_training}</div>
                                <div className="athSubInfo">Coach / Training Partners: {this.state.athlete_arr[0].coach_partners}</div>
                                <div className="athSubInfo">Favorite Course Type: {this.state.athlete_arr[0].fav_course_type}</div>
                                <div className="athSubInfo">Passions: {this.state.athlete_arr[0].passions}</div>
                                <div className="athSubInfo">Championship App.: {this.state.stats.champ_app}</div>
                                <div className="athSubInfo">Championship Finals App.: {this.state.stats.champ_final_app}</div>
                            </div>

                        </div>
                        : null}
                </div>


<div className="titleUnderline">
    Regular Season
</div>

                <div className="athBioFlexCnt">
                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Open Stats</div>
                        <div className="statSection">
                            <div>Comps Competed: {this.state.stats.open_comps}</div>
                            <div>Open Clears: {this.state.stats.open_clears}</div>
                            <div>Open Full Clears: {this.state.stats.open_full_clears}</div>
                        </div>
                    </div>

                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Finals Stats</div>
                        <div className="statSection">
                            <div>Finals Appearance: {this.state.stats.reg_finals_app} - {Math.round((this.state.stats.reg_finals_app / this.state.stats.open_comps) * 100)}%</div>
                            <div>Hybrid Clears: {this.state.stats.reg_hybrid_clears}</div>
                            <div>Hybrid Full Clears: {this.state.stats.reg_hybrid_full_clears}</div>
                            <div>Speed Wins: {this.state.stats.reg_speed_wins}</div>
                            <div>Burnout Full Clears: {this.state.stats.reg_burnout_full_clears}</div>
                        </div>
                    </div>

                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Podium Stats</div>
                        <div className="statSection">
                            <div>Podiums: {this.state.stats.reg_podiums}</div>
                            <div>1st Places: {this.state.stats.reg_first_count}</div>
                            <div>2nd Places: {this.state.stats.reg_second_count}</div>
                            <div>3rd Places: {this.state.stats.reg_third_count}</div>
                        </div>
                    </div>

                   

                </div>

                <div className="titleUnderline">
    Post Season
</div>

<div className="athBioFlexCnt">
                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Semi Final Stats</div>
                        <div className="statSection">
                        <div>Hybrid Clears: {this.state.stats.sf_hybrid_clears}</div>
                            <div>Hybrid Full Clears: {this.state.stats.sf_hybrid_full_clears}</div>
                            <div>Speed Wins: {this.state.stats.sf_speed_wins}</div>
                            <div>Burnout Full Clears: {this.state.stats.sf_burnout_full_clears}</div>
                            <div>Placements: 1: {this.state.stats.sf_first_count} | 2: {this.state.stats.sf_second_count} | 3: {this.state.stats.sf_third_count}</div>
                        </div>
                    </div>

                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Finals Stats</div>
                        <div className="statSection">
                            <div>Finals Appearance: {this.state.stats.champ_final_app} - {Math.round((this.state.stats.champ_final_app / this.state.stats.champ_app) * 100)}%</div>
                            <div>Hybrid Clears: {this.state.stats.post_hybrid_clears}</div>
                            <div>Hybrid Full Clears: {this.state.stats.post_hybrid_full_clears}</div>
                            <div>Speed Wins: {this.state.stats.post_speed_wins}</div>
                            <div>Burnout Full Clears: {this.state.stats.post_burnout_full_clears}</div>
                        </div>
                    </div>

                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Podium Stats</div>
                        <div className="statSection">
                            <div>Podiums: {this.state.stats.post_podiums}</div>
                            <div>1st Places: {this.state.stats.post_first_count}</div>
                            <div>2nd Places: {this.state.stats.post_second_count}</div>
                            <div>3rd Places: {this.state.stats.post_third_count}</div>

                        </div>
                    </div>

                   

                </div>


<div className="titleUnderline">
    Competitions
</div>
                {/* <div className="athBioFlexCnt"> */}
                <div className="athSummaryContainer">
                        <div className="athName">Top 5 Comps All Time</div>
                        <div className="statSection">
                        <div className="statTableDiv" style={{fontWeight: "normal", height: "auto"}}>
                        <table className="statTable">
                            <th>Season</th>
                            <th>Gym</th>
                            <th>Overall</th>
                            <th>Hybrid</th>
                            <th>Speed</th>
                            <th>Burnout</th>
                            <th>Open</th>
                            <th>Points</th>

                            <thead></thead>
                            {this.state.top_5_comps.map((item, index) => {
                                return <tr>
                                    <td>{item.season_id}</td>
                                    <td>{item.gym_name}</td>
                                    <td>{item.athlete_rank}</td>
                                    <td>{item.hybrid_rank}</td>
                                    <td>{item.speed_rank}</td>
                                    <td>{item.burnout_rank}</td>
                                    <td>{item.open_rank}</td>
                                    <td>{item.points}</td>
                                </tr>
                            })}
                        </table>
                    </div>
                        </div>
                    </div>

                    <div className="athSummaryContainer">
                        <div className="athName">Most Recent Comps</div>
                        <div className="statSection">
                        <div className="statTableDiv" style={{fontWeight: "normal", height: "auto"}}>
                        <table className="statTable">
                            <th>Season</th>
                            <th>Gym</th>
                            <th>Overall</th>
                            <th>Hybrid</th>
                            <th>Speed</th>
                            <th>Burnout</th>
                            <th>Open</th>
                            <th>Points</th>

                            <thead></thead>
                            {this.state.recent_comps.map((item, index) => {
                                return <tr>
                                    <td>{item.season_id}</td>
                                    <td>{item.gym_name}</td>
                                    <td>{item.athlete_rank}</td>
                                    <td>{item.hybrid_rank}</td>
                                    <td>{item.speed_rank}</td>
                                    <td>{item.burnout_rank}</td>
                                    <td>{item.open_rank}</td>
                                    <td>{item.points}</td>
                                </tr>
                            })}
                        </table>
                    </div>
                        </div>
                    </div>
                {/* </div> */}

                

                <div className="athSummaryContainer">
                    <div className="athName">Full Competition History</div>
                    <div className="statTableDiv">
                        <table className="statTable">
                            <th>Season</th>
                            <th>Gym</th>
                            <th>Overall</th>
                            <th>Hybrid</th>
                            <th>Speed</th>
                            <th>Burnout</th>
                            <th>Open</th>
                            <th>Points</th>

                            <thead></thead>
                            {this.state.all_comps.map((item, index) => {
                                return <tr>
                                    <td>{item.season_id}</td>
                                    <td>{item.gym_name}</td>
                                    <td>{item.athlete_rank}</td>
                                    <td>{item.hybrid_rank}</td>
                                    <td>{item.speed_rank}</td>
                                    <td>{item.burnout_rank}</td>
                                    <td>{item.open_rank}</td>
                                    <td>{item.points}</td>
                                </tr>
                            })}
                        </table>
                    </div>

                </div>

</div>
: <div className="athName">This Athlete does not yet have stats</div>
                        }

                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}


export default AthleteBio;