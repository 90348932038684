// we import all the various reducers we created, to be combined using the Redux function `combineReducers()`
import { combineReducers } from 'redux';
import authReducer from './auth_reducer';

//************************************************ */
//Persisted reducer configuration 
//************************************************ */

const rootReducer = combineReducers({
  auth: authReducer
})

export default rootReducer