import React, { Component } from 'react';
import '../style/main.css';
import '../style/reused_css.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faPatreon } from '@fortawesome/free-brands-svg-icons';
import Registration from "../components/Registration";
import YouthChamp from '../static/Youth_Champ_Insta_Post.png';


library.add(faYoutube, faPatreon);

class Home extends Component {

    state = {

    }



    componentDidMount() {

    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
                <div>
                    {/* <div className="infoBanner">
                        NSC Season II Registration is now Live! Scroll Down for Links.
                    </div> */}
                    <div className="flex-container-row">
                        <div className="fullscreenVideo">
                            <div class='embed-container'><iframe className="mainVideo" src={"https://player.vimeo.com/video/736682123?autoplay=1&loop=1&autopause=0&background=1&muted=1"} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>

                            <div className="videoMask">
                                <div className="flex-center-center">
                                    <div className="videoTitle" style={{ marginBottom: "20px" }}>NINJA SPORT NETWORK</div>
                                    <div className="flex-row">
                                        <div style={{ padding: "5px" }}><a className="videoLink" href="https://www.youtube.com/channel/UCeidwTomRFqkERADxH2iYpA" target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} /> YouTube</a></div>
                                        <div style={{ padding: "5px" }}><a className="videoLink" href="https://www.patreon.com/ninjasportnetwork" target="_blank"><FontAwesomeIcon icon={['fab', 'patreon']} /> Patreon</a></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container-row">

                        <div className="homeContent">

                            {/* <div className="appHighlights">
              <div className="sectionDesc" style={{textAlign: "center"}}>
              Our goal is to provide the highest quality coverage of professional ninja sporting events, highlights, and news. Ninja is our passion, and we are dedicated to growing the sport by making it more accessible to viewers everywhere.
                <br/>Competitions are streamed on <a style={{color: "rgb(148,194,228)"}} href="https://www.youtube.com/channel/UCeidwTomRFqkERADxH2iYpA" target="_blank">Youtube</a>
                <br/>If you want to help us in our mission, and also get some cool stuff along the way, check out our <a style={{color: "rgb(148,194,228)"}} href="https://www.patreon.com/ninjasportnetwork" target="_blank">Patreon</a>
              </div>
            </div> */}

                            


                            <div className="gymDiv">
                            
                                <div className="NSCTitle">NINJA SPORT CHAMPIONSHIP</div>
                                <div className="sectionDesc" style={{ marginTop: "30px" }}>NSC is a ninja competition series featuring the best athletes on professional level courses. Our mission is to show more of the top athletes, more often, and on a variety of courses ranging from Speed courses to grippy Burnout courses. </div>
                                {/* <div className="upcoming">Upcoming Competitions</div> */}

                                </div>

                                <div className="blueGradient" style={{marginBottom: "15px"}}>
                                    <div className="flex-container-row">
                                        <div style={{flex: "1", minWidth: "353px"}}>
                                            <img src={YouthChamp} width="100%" height="100%"></img>
                                        </div>
                                        <div style={{flex: '2', margin: "0px 10px"}}>
                                        <div className="blockTitle">Announcing the NSC Youth Championship!</div>
                                        <div className="blockSubTitle" style={{marginTop: "10px"}}>Rock Solid Warrior | Aug 31 & Sep 1 | South Apex NC</div>
                                        <div className="baseText" style={{marginTop: "10px"}}>The NSC Youth Championship will be a 2 day event, all athletes will compete on two courses on day 1 the Hybrid course and the Speed Course. The top 10 boys and top 10 girls from each age division will advance to compete in the finals on Sunday where they will run all 3 courses, the Hybrid and Speed again, and then an added Burnout course.</div>
                                        <div style={{width: "100%"}}><a className="nscBtn" href="/youth_championship">Learn More!</a></div>
                                        </div>
                                    </div>
                                </div>

                                <HeroImg title='NSC Competition Registration' page='registration_image'></HeroImg>

                                <Registration></Registration>

                        </div>
                    </div>
                </div>
                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}


export default Home;