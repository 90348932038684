import React, { Component } from 'react';
import '../style/main.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import Registration from "../components/Registration";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import '../style/graphic_leaderboard.css';
import { getPowerRanking } from '../api/api';
import seasonThree from '../static/season_three.jpg';




library.add(faUser, faCaretDown, faCaretUp);

class CompRegistration extends Component {

    state = {
        
    }

    componentDidMount() {
       
    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
                    <div>
                        <HeroImg page="registration_image" title="Season IV Registration Coming Soon!"></HeroImg>
                    </div>

                    <div>
                {/* <div><img src={seasonThree} width="100%"></img></div> */}
                </div>

                <Registration></Registration>
                    
                   
                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}


export default CompRegistration;