import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';
import '../style/ath_hist.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {getAthleteHistory } from '../api/api';
import NSC_Logo from '../static/NSC-Blue-PNG-Outline.png';
import { getLatestMessage } from '../api/api';
import cable from 'actioncable';
import actioncable from 'actioncable';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';


library.add(faCircle, faTimes);

function getDefaultState() {
  return {
    ws: null,
    comp_id: '',
    athlete_history: [],
    athlete_name: ''
  }
}

class NinjaMasterAPI extends Component {

  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.timerRef = null;
  }



  distributeMessage(message){
    this.props.updateGraphics(message);

  }

  getMessage(){
    
  }

  connectedFunction(data){
    console.log('Connected Success');
  }

  equals(newProp, oldProp){
    var test = false;
    if(newProp.channel == oldProp.channel && newProp.courseId == oldProp.courseId){
      test = true;
    }
    return test;
  }


  componentDidMount() {
    console.log(this.props);
    // this.cable = actioncable.createConsumer('wss://ninjamastersoftware.com/cable');
    // this.ninjaChannel = this.cable.subscriptions.create({
    //     channel: this.props.channel, 
    //     course_id: this.props.courseId,
    //     wave_id: this.props.waveId
    // },{
    //     connected: () => {
    //         console.log("connected!")
    //     },
    //     disconnected: () => {console.log('Action Cable Disconnected')},
    //     received: data => {
    //         console.log('Data Received');
    //         console.log(data);
    //         this.distributeMessage(data);
    //     }
    // })
    console.log(this.props.actionCableUrl);
  }

  componentWillUnmount() {
    
    
  }

  shouldComponentUpdate(nextProps, nextState){
    return !this.equals(nextProps, this.props);
  }

  render() {
    console.log('Action Cable is Re-Rendering');
    console.log('Props - channel = ' + this.props.channel + ' courseId = ' + this.props.courseId);
    return (
      <div>

        <ActionCableProvider url={this.props.actionCableUrl}>
            <ActionCableConsumer channel={({channel: this.props.channel, course_id: this.props.courseId, wave_id: this.props.waveId})} onReceived={this.distributeMessage.bind(this)} onConnected={this.connectedFunction.bind(this)}></ActionCableConsumer>
        </ActionCableProvider>

<div>
        {/* <button className='nsnBtn' onClick={e => this.getMessage()}>Get Message</button> */}
        </div>
    
      </div>
    );
  }
}

export default NinjaMasterAPI;