import React, { Component } from 'react';
import '../style/main.css';
import { withRouter, Route, Link } from 'react-router-dom';
import RankHero from '../static/nacssa_championship.jpg';
import RulesHero from '../static/rules_hero.jpg';
import YouthChamp from '../static/youth_champ.jpg';
import RegistrationImg from '../static/registration_image.jpg';
import SponsorImg from '../static/sponsor_image.jpg';


class HeroImg extends Component { 

    constructor(props) {
        super(props);
      }

    state={
        menuOpen: false,
        toggleDropdown: false,
        image: RankHero
    }



    goTo(str){
        this.props.history.push('/' + str);
    }
          

    componentDidMount(){
        console.log(this);
        var imgChoice;
        switch(this.props.page){
            case 'nsc_athlete_ranking':
                imgChoice = RankHero;
                break;
            case 'rules':
                imgChoice = RulesHero;
                break;
            case 'youth_championship':
                    imgChoice = YouthChamp;
                    break;
                    case 'registration_image':
                    imgChoice = RegistrationImg;
                    break;
                    case 'sponsor_image':
                    imgChoice = SponsorImg;
                    break;
            default:
                imgChoice = RankHero;
        }
        this.setState({image: imgChoice});
    }

    render() {
        return (
            <div>
                    <div className="flex-container-row">
                        <div className="heroImgContainer">
                            <img src={this.state.image} width="100%" height="auto"></img>
                            <div className="heroMask">
                                    <div className="heroTitle" style={{ marginBottom: "20px" }}>{this.props.title}</div>
                                    <div className="flex-row">

                                    </div>
                            </div>
                        </div>
                    </div>

            </div>
        );
    }
}


export default (withRouter(HeroImg));
