import React, {Component} from 'react';
import NSNHeader from "../components/header";
import NSNFooter from "../components/footer";
import '../style/main.css';
import '../style/videos.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock} from '@fortawesome/free-solid-svg-icons';
// import actionPhoto from '../static/digangi_pic.jpg';
// import gymPhoto from '../static/chicago_gym.jpg';
// import promoPoster from '../static/promoPoster.png';
// import { connect } from 'react-redux';
// import {setUserToReduxState, logoutUserFromReduxState} from '../actions/auth_actions';
// import {verifyUser} from '../functions/auth_functions';
// import Autocomplete from '../components/autocomplete';
// import VideoPlayer from '../components/video_player.js';
import {getVideoCollection} from '../api/api.js';
import loadingImg from '../static/NSC-Blue-PNG-Outline.png';
// import IntegrationReactSelect from '../components/react_autocomplete';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import queryString from 'query-string';
// import {getEventList} from '../api/stats_api';




library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock);


class VideoCollection extends Component {

  state={
    openFree: false,
    openPremium: false,
    videoArr: [],
    loading: true,
    openDialog: false,
    dialogTitle: "", 
    dialogMsg: "",
    showVideos: false,
    athletePage: false,
    eventArr: [],
    selectedObject: {},
    selectedEvent: ''
  }

  dynamicBackground = (imgSrc) => ({
    backgroundImage: `url(${imgSrc})`
  })

  getResults = e =>{
    // console.log(e.target.value);
    var selectedEvent = e.target.value;
    // getEventResults(this.state.eventArr[selectedEvent].event_id).then(resp=>{
    //   // console.log(resp);
    //   this.setState({showLeaderboard: true, resultsArr: resp.results, selectedEvent:  this.state.eventArr[selectedEvent].event_name, selectedObj:  this.state.eventArr[selectedEvent]});
    // })

    //add event id
    // getVideoCollection('video_type_id', 3, this.props.auth.user.sub_status, ' and source_id = ' + this.state.eventArr[selectedEvent].event_id).then(resp=>{
      getVideoCollection('video_type_id', 3, 'active', ' and source_id = ' + this.state.eventArr[selectedEvent].event_id).then(resp=>{
      // console.log(resp);
      this.setState({showVideos: true, videoArr: resp.videos, loading: false, showVideos: true, selectedEvent:  this.state.eventArr[selectedEvent].event_name, selectedObj:  this.state.eventArr[selectedEvent]});
  }).catch(err=>{
      console.log(err);
  });


  }

    
  toggleDiv(type){
    var divState;
    if(this.state[type]==true){
      divState = false;
    }else{
      divState = true;
    }
    this.setState({[type]: divState});
  }

  goTo(str , id, type, active, premium){
      this.props.history.push({
          pathname: '/' + str,
          search: '?type=' + type + "&id=" + id,
          state: { type: type, id: id }
      })
}

// upgradeLink = () => {
//   this.props.history.push('/checkout');
// }

// closeDialog = (type) =>{
//   this.setState({ openDialog: false, dialogTitle: "", dialogMsg: "" });
// }

formatDate(val){
  var year = val.split("-")[0];
  var month = val.split("-")[1];  
  var day = val.split("-")[2];
  return (month + "/" + day + "/" + year);
}


// componentWillMount(){
//     verifyUser(this.props.auth).then(resp=>{
//     }).catch(err=>{
//         console.log(err);
//         this.props.logoutUserFromReduxState();
//     });
//   }

  componentDidUpdate (prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      window.location.reload();
    }
}

  

  componentDidMount(){
    console.log(this);
    var queryObj = queryString.parse(this.props.location.search);
      // getVideoCollection(queryObj.type, queryObj.id, this.props.auth.user.sub_status, '').then(resp=>{
        getVideoCollection(queryObj.type, queryObj.id).then(resp=>{
          // console.log(resp);
          this.setState({videoArr: resp.videos, loading: false, showVideos: true});
      }).catch(err=>{
          console.log(err);
      });
  }

    render() {
        if(this.state.loading == true){
            return (<div className="mainDiv" style={{height: "100vh"}}>
                <NSNHeader title='' link='' linkTitle=''></NSNHeader>
                <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                    <div className="pulseImg">
                    <img src={loadingImg} width="200px" height="auto" />
                    </div>
                    </div>
                    </div>
            )
        }else{
      return (
          <div className="mainDiv">
            <NSNHeader title='' link='' linkTitle=''></NSNHeader>
            <div className="pageContainer" style={{marginTop: "10px"}}>

          {this.state.athletePage == true ?
          <div>
            <div className="lbTitle">Select a competition to view athlete runs!</div>
            <div className="selectOptions">
            <select className="selectBox" style={{fontSize: '14px'}} value={this.state.selectedObject.event_name} onChange={e=>this.getResults(e)}>
                                       <option value=''>Select Competition</option>
                                       {this.state.eventArr.map((item,index)=>{
                                         return <option value={index}>{item.event_name}</option>
                                       })} 
                                    </select>
                              </div>
                      </div> : null
                            }

            {this.state.showVideos == true ?
                <div className="darkContainerRow">
                <div className="flex-container-row" style={{justifyContent: "space-between"}}>
                  {/* <div className="darkContainerTitle">UNX Full Runs</div>
                  <div className="darkContainerTitle" style={{color: "rgb(77,198,231)", cursor: "pointer"}}>See All</div> */}
                  </div>
                    <div className="flex-container-row">

                    {this.state.videoArr.map((item, index) => {
                        return <div className="videoMainChoice">
                        <div className="imgMainContainer">

                                <div className="aspect-ratio-box" style={this.dynamicBackground(item.thumbnail_url)} onClick={e=>this.goTo('video_page', item.video_id, item.video_type)}>
                                {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                        <div className="flexbox-centering">
                                        <div className="viewport-sizing">
                                        {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                            <div className="videoMainMargins">
                                <div className="videoMainTitles">{item.title}</div>
                                {item.active == 1 ? <div>
                                    {/* <div className="videoMainSubTitles">{item.description.split(" | ")[0]}</div> */}
                                    {/* <div className="videoMainSubTitles">{item.description.split(" | ")[1]}</div> */}
                                    </div>
                                : <div>
                                    <div className="videoMainSubTitles">{item.description}</div>
                                    {/* <div className="videoMainSubTitles">Coming Soon | {this.formatDate(item.release_date.split("T")[0])}</div> */}
                                </div>}
                            </div>
                        </div>
                        })}
                    </div>
                </div> : null
                }
              
              </div>
              <NSNFooter title='' link='' linkTitle=''></NSNFooter>
              {/* <Dialog
                open={this.state.openDialog}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                            {this.state.dialogMsg}      
                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <button type="button" onClick={this.upgradeLink} className="stripeButton">
                                    Upgrade
                    </button>
                            <button type="button" onClick={this.closeDialog} className="stripeButton">
                                    Close
                    </button>
                            </DialogActions>
                        </Dialog> */}
          </div>
      );
    }
    }
  }


export default VideoCollection;
