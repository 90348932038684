import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getRunOrder_LeaderBoard, getLatestMessage } from '../api/api';
import NSC_Logo from '../static/NSC-Blue-PNG-Outline.png';


library.add(faCircle, faTimes);

function getDefaultState() {
  return {
    ws: null,
    comp_id: '',
    results: [],
    course_type: 'open_results',
    result_type: 'leaderboard',
    scoreboard_gender: '',
    overall_results: [],
    athlete_results: [],
    athlete_name: ''
  }
}

class MiniLB extends Component {

  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.timerRef = null;
  }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = (compId) => {
    var ws = new WebSocket("wss://7o5i09cjfa.execute-api.us-east-2.amazonaws.com/production?comp_id=" + compId);
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log("Mini LBs Connected");

      this.setState({ ws: ws });
      //   var wsObj = {uid: "0", clock_status: "warming", event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: '', time: "00:00.00", current_obstacle: this.props.obstacleInd, total_obstacles: this.props.obstacleCnt}
      //   this.sendMessage({"action": "clockUpdate", "message": JSON.stringify(wsObj) }); //send to websocket if live


      that.timeout = 250; // reset timer to 250 on open of websocket connection 
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };


    ws.onmessage = evt => {
      // listen to data sent from the websocket server

      const message = evt.data;
      console.log(evt);

      if (evt.data.length > 10) {
        this.updateGraphics(evt.data);
      }

    }

    // websocket onclose event listener
    ws.onclose = e => {
      if (e.type !== 'close') {
        console.log(
          `Socket is closed. Reconnect will be attempted in ${Math.min(
            10000 / 1000,
            (that.timeout + that.timeout) / 1000
          )} second.`,
          e.reason
        );

        that.timeout = that.timeout + that.timeout; //increment retry interval
        connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
      }
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  sendMessage = (data) => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
    //   console.log(error) // catch error
    }
  }

  closeSocket = () => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.close() //send data to the server
    } catch (error) {
    //   console.log(error) // catch error
    }
  }


  updateGraphics(message){
    var parseData = JSON.parse(message.slice(0, -1));
    if (parseData.clock_status == "athlete_submitted" || parseData.clock_status == "warming") {
      var courseDesc;
      switch (parseData.result_table) {
        case 'open_results':
          courseDesc = 'Open Round';
          break;
        case 'speed_results':
          courseDesc = 'Speed Course';
          break;
        case 'hybrid_results':
          courseDesc = 'Hybrid Course';
          break;
        case 'burnout_results':
          courseDesc = 'Burnout Course';
          break;

      }
      this.setState({
        course_desc: courseDesc
      });

      getRunOrder_LeaderBoard(parseData.event_id, parseData.result_table, 'leaderboard').then(resp => {
        getRunOrder_LeaderBoard(parseData.event_id, 'overall_results', 'leaderboard').then(resp2 => {
            this.setState({ comp_id: parseData.event_id, results: resp.results, course_type: parseData.result_table, result_type: 'leaderboard', scoreboard_gender: parseData.gender, overall_results: resp2.results });
        });
      });
  }
  }


  getMessage(){
    getLatestMessage().then(resp=>{
      console.log(resp);
      var arr = resp.message.Items;
      if(arr.length > 0){
        for(var i=0; i<arr.length; i++){
          if(arr[i].message_id == 'athlete_submitted'){
            this.updateGraphics(arr[i].message);
            break;
          }
        }
      }
    });
  }

  componentWillReceiveProps(nextProps){
    console.log(this.props.compId);
    console.log(nextProps);
    if(nextProps.compId !== ""){
      //make a api call here
      this.connect(nextProps.compId);
    }
   }


  componentDidMount() {
    console.log(this);
    // this.connect();
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      this.closeSocket();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  componentWillUnmount() {
    this.closeSocket();
  }

  render() {
    return (
      <div>
        
<div>
        <button className='nsnBtn' onClick={e => this.getMessage()}>Get Message</button>
        </div>
 <div>
          {this.state.results.length > 0 && this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length > 0 ?
            <div>

              <div className="LCleaderboardDivision">
                <div className="LCathleteDivision">
                  
                  
                  <div className="miniScoreboard">
                  <div className="LCscoreboardTitleBox" style={{ marginTop: "10px" }}>
                    <div style={{ textAlign: "center" }}>
                      <div className="LCscoreboardSubTitle" style={{ color: "#94C2E4" }}>
                        {this.state.scoreboard_gender == 'Male' ? <span>Men's</span> : this.state.scoreboard_gender == 'Female' ? <span>Women's</span> : <span>Overall</span>}
                        {this.state.course_type == 'open_results' ? <span> Open Course</span>
                          : this.state.course_type == 'speed_results' ? <span> Speed Course</span>
                            : this.state.course_type == 'hybrid_results' ? <span> Hybrid Course</span>
                              : this.state.course_type == 'burnout_results' ? <span> Burnout Course</span>
                                : <span> Overall Results</span>}

                      </div>
                    </div>
                  </div>

                  {this.state.course_type != 'overall_results' ?
                    <div style={{ marginTop: "5px" }}>
                      <table className="LCscoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                        <thead className="LCtableHeader">
                          <th colspan="2" style={{ textAlign: "left", paddingLeft: "10px" }}>ATHLETE</th>
                          <th>LOC</th>
                          <th>TF</th>
                          <th>TIME</th>
                        </thead>
                        <tbody>
                          {this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).slice(0, 5).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                            if (item.gender == this.state.scoreboard_gender) {
                              return <tr className="LCathleteBox">
                                <td className="LCrankCell">{item.athlete_rank}</td>
                                <td className="LCnameCell" style={{ paddingLeft: "5px" }}><div style={{ width: '120px' }}>{item.first_name.slice(0, 1)}. <strong>{item.last_name.slice(0, 7)}</strong></div></td>
                                <td className="LClocCell">{item.points}</td>
                                <td className="LClocCell">{item.total_fails}</td>
                                {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <td className="LCtieTime LCtimeCell">{item.tiebreak_time.substr(3)}</td> : <td className="LCtieTime LCtimeCell"></td>}
                              </tr>
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                  }
                  </div>


                  <div className="miniScoreboard">
                  <div className="LCscoreboardTitleBox" style={{ marginTop: "10px" }}>
                    <div style={{ textAlign: "center" }}>
                      <div className="LCscoreboardSubTitle" style={{ color: "#94C2E4" }}>
                        {this.state.scoreboard_gender == 'Male' ? <span>Men's</span> : this.state.scoreboard_gender == 'Female' ? <span>Women's</span> : <span>Overall</span>}
                        {this.state.course_type == 'open_results' ? <span> Open Course</span>
                          : this.state.course_type == 'speed_results' ? <span> Speed Course</span>
                            : this.state.course_type == 'hybrid_results' ? <span> Hybrid Course</span>
                              : this.state.course_type == 'burnout_results' ? <span> Burnout Course</span>
                                : <span> Overall Results</span>}

                      </div>
                    </div>
                  </div>

                  {this.state.course_type != 'overall_results' ?
                    <div style={{ marginTop: "5px" }}>
                      <table className="LCscoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                        <thead className="LCtableHeader">
                          <th colspan="2" style={{ textAlign: "left", paddingLeft: "10px" }}>ATHLETE</th>
                          <th>LOC</th>
                          <th>TF</th>
                          <th>TIME</th>
                        </thead>
                        <tbody>
                          {this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).slice(0, 3).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                            if (item.gender == this.state.scoreboard_gender) {
                              return <tr className="LCathleteBox">
                                <td className="LCrankCell">{item.athlete_rank}</td>
                                <td className="LCnameCell" style={{ paddingLeft: "5px" }}><div style={{ width: '120px' }}>{item.first_name.slice(0, 1)}. <strong>{item.last_name.slice(0, 7)}</strong></div></td>
                                <td className="LClocCell">{item.points}</td>
                                <td className="LClocCell">{item.total_fails}</td>
                                {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <td className="LCtieTime LCtimeCell">{item.tiebreak_time.substr(3)}</td> : <td className="LCtieTime LCtimeCell"></td>}
                              </tr>
                            }
                          })}
                          {this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length >= 10 ?
                            <tr className="LCathleteBox">
                              <td className="LCrankCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].athlete_rank}</td>
                              <td className="LCnameCell" style={{ paddingLeft: "5px", color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}><div style={{ width: '120px' }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].first_name.slice(0, 1)}. <strong>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].last_name.slice(0, 8)}</strong></div></td>
                              <td className="LClocCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].points}</td>
                              <td className="LClocCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].total_fails}</td>
                              {this.state.course_type != 'overall_results' && this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].tiebreak_time != '' && this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].tiebreak_time != null ? <td className="LCtieTime LCtimeCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[9].tiebreak_time.substr(3)}</td> : <td className="LCtieTime LCtimeCell"></td>}
                            </tr>
                            :
                            <tr className="LCathleteBox">
                              <td className="LCrankCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].athlete_rank}</td>
                              <td className="LCnameCell" style={{ paddingLeft: "5px", color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}><div style={{ width: '120px' }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].first_name.slice(0, 1)}. <strong>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].last_name.slice(0, 8)}</strong></div></td>
                              <td className="LClocCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].points}</td>
                              <td className="LClocCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].total_fails}</td>
                              {this.state.course_type != 'overall_results' && this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].tiebreak_time != '' && this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].tiebreak_time != null ? <td className="LCtieTime LCtimeCell" style={{ color: "var(--nsn-green)", backgroundColor: "rgba(0,0,0,0.95)" }}>{this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender)[this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length - 1].tiebreak_time.substr(3)}</td> : <td className="LCtieTime LCtimeCell"></td>}
                            </tr>}
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                  }
                  </div>

                  {this.state.overall_results.length > 0 ?

                    <div className="miniScoreboard">
                      <div className="LCscoreboardTitleBox" style={{ marginTop: "10px" }}>
                        <div style={{ textAlign: "center" }}>
                          <div className="LCscoreboardSubTitle" style={{ color: "#94C2E4" }}>
                            {this.state.scoreboard_gender == 'Male' ? <span>Men's Overall</span> : this.state.scoreboard_gender == 'Female' ? <span>Women's Overall</span> : null}


                          </div>
                        </div>
                      </div>

                      <table className="LCscoreboardTable LCoverallTable" style={{ width: "100%" }}>
                        <thead className="LCtableHeader">
                          <th colspan="2" style={{ textAlign: "left", paddingLeft: "10px" }}>ATHLETE</th>
                          <th>Pts.</th>
                          <th>S</th>
                          <th>H</th>
                          <th>B</th>
                        </thead>
                        <tbody>
                          {this.state.overall_results.filter(gen => gen.gender == this.state.scoreboard_gender).slice(0, 5).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                            if (item.gender == this.state.scoreboard_gender) {
                              return <tr className="LCathleteBox">
                                <td className="LCrankCell">{item.athlete_rank}</td>
                                <td className="LCnameCell" style={{ paddingLeft: "5px" }}><div style={{ width: '120px' }}>{item.first_name.slice(0, 1)}. <strong>{item.last_name.slice(0, 7)}</strong></div></td>
                                <td className="LClocCell">{item.points}</td>
                                <td className="LClocCell">{item.speed_rank}</td>
                                <td className="LClocCell">{item.hybrid_rank}</td>
                                <td className="LClocCell">{item.burnout_rank}</td>
                              </tr>
                            }
                          })}
                        </tbody>
                      </table>
                      {this.state.advanced_details ? <div className="legend">* TB1 = Tie Breaker 1 (Top 2 Places) | TB2 = Tie Breaker 2 (Top Place)</div> : null}
                    </div>
                    : null}

                </div>

              </div>

            </div>
            : null}
        </div>
        {/* End of Leaderboard Section xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
      </div>
    );
  }
}

export default MiniLB;