import Amplify, { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports';
import {getUser} from '../api/aws/amplify_api';

Amplify.configure(aws_exports);

export function verifyUser(auth){
    const p = new Promise((res,reject)=>{
        if(auth.authenticated == true){    
            getUser().then(user=>{
                console.log('this');
                console.log(user);
                    if(user.attributes.sub !== auth.user.id){
                        reject({verified: false, msg: 'User id does not match'});
                    }else{
                        res({verified: true, msg: "User verified"});
                    }
            })
            .catch(err => {
                reject({verified: false, msg: err});
            });
        }
    })
    return p;
}