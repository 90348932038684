// import axios from 'axios';
import Amplify, { API } from 'aws-amplify';
import aws_exports from '../aws-exports';
// import { maxHeaderSize } from 'http';

Amplify.configure(aws_exports);

/*
export function addCompetitor(firstName, lastName, age, location, gender){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/add_competitor';
        let myInit = {
            body: {
                firstName: firstName,
                lastName: lastName,
                age: age,
                location: location,
                gender: gender
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getEventList(location){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/get_event_list';
        let myInit = {
            queryStringParameters: {
                
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getCompInfo(event){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/get_comp_info';
        let myInit = {
            queryStringParameters: {
                event: event
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getAthletes(location, max, min){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/get_athletes';
        let myInit = {
            queryStringParameters: {
                location: location,
                ageMax: max,
                ageMin: min
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}


export function postScorecard(result_id, event, points, tieOB, tieTime, resStr, gender, comp_type, athlete){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '';
        console.log(comp_type);
        if(comp_type == 3 || comp_type == 4){
            path = '/api/post_major_final';
        }else{
            path = '/api/post_scorecard';
        }
        let myInit = {
            body: {
                result_id: result_id,
                event: event,
                points: points,
                tieOB: tieOB,
                tieTime: tieTime,
                resStr: resStr,
                gender: gender,
                athlete_id: athlete
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function postUpdatedScorecard(athlete, course, points, tieOB, tieTime, resStr){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/post_updated_scorecard';
        let myInit = {
            body: {
                athlete: athlete,
                course: course,
                points: points,
                tieOB: tieOB,
                tieTime: tieTime,
                resStr: resStr
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function postStandings(athlete, course, points){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/post_standings';
        let myInit = {
            body: {
                athlete_id: athlete.athlete_id,
                course: course,
                rank: points
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function updateStandings(athlete, total){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/update_season_standings';
        let myInit = {
            body: {
                athlete_id: athlete.athlete_id,
                total_athletes: total
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function postUpdatedRankings(obj){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/update_season_rankings';
        let myInit = {
            body: {
                athlete_id: obj.ath,
                ranking: obj.rank
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getStandings(ageMin, ageMax, location){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/get_standings';
        let myInit = {
            queryStringParameters: {
                ageMin: ageMin,
                ageMax: ageMax,
                location: location
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function submitSurveyResults(obj){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/post_survey';
        let myInit = {
            body: {
                email: obj.email,
                location: obj.location,
                age: obj.age,
                feedback: obj.feedback
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}


export function getLiveLeaderboard(event, gender){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/get_live_leaderboard';
        let myInit = {
            queryStringParameters: {
                event: event,
                gender: gender
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getLiveRunOrder(event, gender){
    const p = new Promise((res,reject)=>{
        let apiName = 'unxscoreapi';
        let path = '/api/get_live_run_order';
        let myInit = {
            queryStringParameters: {
                event: event,
                gender: gender
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}*/

export function getGyms(){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_gyms';
        let myInit = {
            queryStringParameters: {

            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getCompCourses(id){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_comp_courses';
        let myInit = {
            queryStringParameters: {
                comp: id
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getCourseObstacles(id){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_obstacles';
        let myInit = {
            queryStringParameters: {
                course: id
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getCompAthletes(id, table){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_comp_athletes';
        let myInit = {
            queryStringParameters: {
                comp: id,
                table: table
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getComps(){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_comps';
        let myInit = {
            queryStringParameters: {

            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getAllComps(){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_all_comps';
        let myInit = {
            queryStringParameters: {

            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getRunOrder_LeaderBoard(comp_id, result_table, type){
    const p = new Promise((res,reject)=>{
        console.log('Result Table ' + result_table)
        let apiName = 'APINSN';
        let path = '/score/get_run_order_leaderboard';
        let myInit = {
            queryStringParameters: {
                comp: comp_id,
                table: result_table,
                type: type
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getAthleteResult(comp_id, result_table, athlete){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_athlete_result';
        let myInit = {
            queryStringParameters: {
                comp: comp_id,
                table: result_table,
                athlete: athlete
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getAthleteHistory(athlete){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_athlete_history';
        let myInit = {
            queryStringParameters: {
                athlete: athlete
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getPowerRanking(){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_power_ranking';
        let myInit = {
            queryStringParameters: {
                
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getLatestMessage(){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_latest_message';
        let myInit = {
            queryStringParameters: {
                
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getAthleteBio(athlete_id){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_athlete_bio';
        let myInit = {
            queryStringParameters: {
                athlete_id: athlete_id
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getAthleteIdFromName(first_name, last_name){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/get_athlete_id_from_name';
        let myInit = {
            queryStringParameters: {
                first_name: first_name,
                last_name: last_name
            }
        }
        API.get(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function postGym(gym_name, gym_address){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/post_gym';
        let myInit = {
            body: {
                gym_name: gym_name,
                gym_address: gym_address
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function postCompetition(gym, compDate,){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/post_competition';
        let myInit = {
            body: {
                gym: gym,
                compDate: compDate
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function postCourse(comp_id, course_type, courseTime, resetTime, obstacleArr){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/post_course';
        let myInit = {
            body: {
                comp_id: comp_id,
                course_type: course_type,
                course_time: courseTime,
                reset_time: resetTime,
                obstacles: obstacleArr
            }
        }
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}


export function postScorecard(result_id, comp_id, points, tieOB, tieTime, resStr, gender, athlete, first_fail, total_fails, course_id, result_table){
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/score/post_scorecard';
        let myInit = {
            body: {
                result_id: result_id,
                comp_id: comp_id,
                points: points,
                tieOB: tieOB,
                tieTime: tieTime,
                resStr: resStr,
                gender: gender,
                athlete_id: athlete,
                first_fail: first_fail,
                total_fails: total_fails,
                course_id: course_id,
                result_table: result_table
            }
        }
        console.log(myInit);
        API.post(apiName, path, myInit).then(resp=>{
            res(resp);
        }).catch(err=>reject(err)); 
    })
    return p;
}

export function getRequestedVideo(vid, type){
    
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/videos/get_requested_video';
        // Auth.currentSession().then(resp=>{
        let myInit = {
            // headers: {CognitoIdToken: 'Bearer ' + resp.idToken.jwtToken},
            queryStringParameters: {video_id: vid, type: type}
        }
        API.get(apiName, path, myInit).then(response => {
            res(response);
        }).catch(error => {
            reject(error);
        });
    // });
    })
    return p;
}

export function getAllVideos(vid, sub_status){
    
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/videos/get_all_videos';
        // Auth.currentSession().then(resp=>{
            let myInit = {
                queryStringParameters: {
                    
                }
            }
        API.get(apiName, path, myInit).then(response => {
            res(response);
        }).catch(error => {
            reject(error);
        });
    // });
    })
    return p;
}

export function getFeaturedVideos(){
    
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/videos/get_featured_videos';
        // Auth.currentSession().then(resp=>{
        let myInit = {
            // headers: {CognitoIdToken: 'Bearer ' + resp.idToken.jwtToken},
            queryStringParameters: {
                
            }
        }
        API.get(apiName, path, myInit).then(response => {
            res(response);
        }).catch(error => {
            reject(error);
        });
    // });
    })
    return p;
}

export function getVideoCollection(type, id, sub_status, event_id){
    
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/videos/get_video_collection';
        // Auth.currentSession().then(resp=>{
        let myInit = {
            // headers: {CognitoIdToken: 'Bearer ' + resp.idToken.jwtToken},
            queryStringParameters: {type: type, id: id}
        }
        API.get(apiName, path, myInit).then(response => {
            res(response);
        }).catch(error => {
            reject(error);
        });
    // });
    })
    return p;
}

export function getSearchText(vid){
    
    const p = new Promise((res,reject)=>{
        let apiName = 'APINSN';
        let path = '/videos/get_searchable_text';
        // Auth.currentSession().then(resp=>{
        let myInit = {
            // headers: {CognitoIdToken: 'Bearer ' + resp.idToken.jwtToken},
            queryStringParameters: {}
        }
        API.get(apiName, path, myInit).then(response => {
            res(response);
        }).catch(error => {
            reject(error);
        });
    // });
    })
    return p;
}