import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';
import '../style/ath_hist.css';
import '../style/bottomLB.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getRunOrder_LeaderBoard, getLatestMessage } from '../api/api';
// import NSC_Logo from '../static/NSC-Blue-PNG-Outline.png';
import MainWebSocket from "../components/WebSocket";
import { liveAthleteScore, addCurrentAthlete } from '../functions/liveScoreFunction';


library.add(faCircle, faTimes);

function getDefaultState() {
    return {
      comp_id: '',
      results: [],
      course_type: 'open_results',
      result_type: 'leaderboard',
      scoreboard_gender: '',
      overall_results: [],
      athlete_results: [],
      athlete_name: '',
        athlete_id: '',
        current_place: null,
        overall_place: null,
        run_order: [],
        score_template: [],
        total_obstacles: 0,
        upper_limit: 15
    }
  }

class BottomLiveLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = getDefaultState();
        this.timerRef = null;
      }
    

    
      updateGraphics = (message) =>{
        var parseData = JSON.parse(message.slice(0, -1));
        console.log(parseData);
        // if (parseData.clock_status == "athlete_submitted" || parseData.clock_status == "warming") {
            if (parseData.clock_status == "warming") {
          var courseDesc;
          var upperLimit;
          switch (parseData.result_table) {
            case 'open_results':
              courseDesc = 'Open Round';
              upperLimit = 15;
              break;
            case 'speed_results':
              courseDesc = 'Speed Course';
              upperLimit = 8;
              break;
            case 'hybrid_results':
              courseDesc = 'Hybrid Course';
              upperLimit = 12;
              break;
            case 'burnout_results':
              courseDesc = 'Burnout Course';
              upperLimit = 5;
              break;
    
          }
          this.setState({
            course_desc: courseDesc, upper_limit: upperLimit
          });
    
          getRunOrder_LeaderBoard(parseData.event_id, parseData.result_table, 'leaderboard').then(resp => {
            getRunOrder_LeaderBoard(parseData.event_id, parseData.result_table, 'run_order').then(ro => {
            getRunOrder_LeaderBoard(parseData.event_id, 'overall_results', 'leaderboard').then(resp2 => {
                addCurrentAthlete(resp.results, parseData.athlete_id, parseData.athlete, parseData.gender).then(resp3 => {
                    this.setState({ comp_id: parseData.event_id, results: resp3.result_arr, current_place: resp3.curr_place, course_type: parseData.result_table, result_type: 'leaderboard', scoreboard_gender: parseData.gender, overall_results: resp2.results, athlete_name: parseData.athlete, athlete_id: parseData.athlete_id, run_order: ro.results, score_template: resp.score_template, total_obstacles: parseData.total_obstacles });
                });
            });
            });
          });
      }else if(parseData.clock_status == 'running' && parseData.fail == false){
        liveAthleteScore(this.state.results, this.state.overall_results, this.state.athlete_id, this.state.athlete_name, this.state.scoreboard_gender, parseData.current_obstacle, parseData.fails, parseData.time, this.state.course_desc, this.state.current_place, this.state.score_template).then(resp=>{
            // if(courseDesc == 'Open Round'){
            setTimeout(()=>{
                this.setState({results: resp.result_arr, current_place: resp.new_place, overall_results: resp.ovr_result, overall_place: resp.ovr_place});
            }, 1750);    
            
            // }else{
            //     liveCompetitionScore(this.state.overall_results, this.state.athlete_id, this.state.athlete_name, this.state.scoreboard_gender, parseData.current_obstacle, parseData.fails, parseData.time, courseDesc).then(ovr=>{
            //         this.setState({results: resp.result_arr, current_place: resp.curr_place, overall_results: ovr.result_arr});
            //     })
            // }
        });
      }
      }

      componentDidMount() {
        console.log(this);
        console.log(this.props.compId);
      }

    render() {
        if(this.props.compId == ""){
            return (<div className="mainDiv" style={{height: "100vh", margin: "0px"}}>
                
                <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                    <div className="pulseImg">

                    </div>
                    </div>
                    </div>
            )
        }else{
        return (
            <div>
                <MainWebSocket compId={this.props.compId} updateGraphics={this.updateGraphics} getMsgParam="warming"></MainWebSocket>

{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
Overall Leaderboard Start
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
                <div>
                    <div style={{height: "720px", width: "1500px" }}>
                        {this.state.results.length > 0 && this.state.results.filter(gen => gen.gender == this.state.scoreboard_gender).length > 0 ?
                            <div style={{marginTop: "20px"}}>

                                <div style={{height: "75px;"}}>
                                    {this.state.overall_results.length > 0 ?

                                        <div className="botLBContain">
                                            <div className="bottomLBFlexRow">
                                                {this.state.overall_results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(0, 4).map((item, index) => {
                                                        if (item.gender == this.state.scoreboard_gender) {
                                                            return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "nonActiveAthlete botLBAthlete"}>
                                                                <div className="botLBAthTitle">
                                                                    {/* <div className="botLBName">{item.athlete_rank}.</div> */}
                                                                    <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 9)}</div></div>
                                                                    <div className="botLBName"><span style={{fontSize: "10px"}}>Pts.</span> {item.points}</div>
                                                                    {/* <div className="botLBName">{item.points}</div>     */}
                                                                </div>
                                                                <div className="botLBAthInfo" style={{justifyContent: "flex-start"}}>
                                                                    
                                                                    {item.hybrid_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">H</div>
                                                                        <div className="botLBLab">{item.hybrid_rank}</div>
                                                                    </div> : null}
                                                                    {item.speed_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">S</div>
                                                                        <div className="botLBLab">{item.speed_rank}</div>
                                                                    </div>  : null}
                                                                    {item.burnout_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">B</div>
                                                                        <div className="botLBLab">{item.burnout_rank}</div>
                                                                    </div>  : null}
                                                                    
                                                                    
                                                                    
                                                                    {this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).length > 0 ? 
                                                                    this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).map((result, index) =>{
                                                                        return <div className="ovrLBAthInfo">
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>LOC</div>
                                                                                <div>{result.points}</div>
                                                                            </div>
                                                                            <div className="botOvrContainer">
                                                                            <div style={{fontSize: "9px"}}>Time</div>
                                                                            {this.state.course_type != 'overall_results' && result.tiebreak_time != '' && result.tiebreak_time != null ? <div className="">{result.tiebreak_time.substr(3)}</div> : <div></div>}
                                                                            </div>
                                                                            {this.state.course_type == "open_results" || this.state.course_type == "hybrid_results" ?
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>Fails</div>
                                                                                <div>{result.total_fails}</div>
                                                                            </div> : null}
                                                                        </div>
                                                                    })
                                                                : <div>{this.state.run_order.filter(obj=> obj.athlete_id == item.athlete_id).map((ro, index) =>{return <div style={{marginLeft: "20px"}}>Run Order - {ro.run_order}</div>})}</div>
                                                                }
                                                                    



                                                                    
                                                                </div>
                                                            </div>
                                                        }
                                                    })}

                                            {this.state.current_place <= this.state.upper_limit && this.state.current_place > 4 ?
                                            this.state.overall_results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(this.state.current_place-1, this.state.current_place).map((item, index) => {
                                                if (item.gender == this.state.scoreboard_gender) {
                                                    return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "bubbleAthlete botLBAthlete"}>
                                                        <div className="botLBAthTitle">
                                                            {/* <div className="botLBName">{item.athlete_rank}.</div> */}
                                                            <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 9)}</div></div>
                                                                    <div className="botLBName"><span style={{fontSize: "10px"}}>Pts.</span> {item.points}</div>
                                                            {/* <div className="botLBName">{item.points}</div>     */}
                                                        </div>
                                                        <div className="botLBAthInfo" style={{justifyContent: "flex-start"}}>
                                                                    
                                                                    {item.hybrid_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">H</div>
                                                                        <div className="botLBLab">{item.hybrid_rank}</div>
                                                                    </div> : null}
                                                                    {item.speed_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">S</div>
                                                                        <div className="botLBLab">{item.speed_rank}</div>
                                                                    </div>  : null}
                                                                    {item.burnout_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">B</div>
                                                                        <div className="botLBLab">{item.burnout_rank}</div>
                                                                    </div>  : null}
                                                                    
                                                                    
                                                                    
                                                                    {this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).length > 0 ? 
                                                                    this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).map((result, index) =>{
                                                                        return <div className="ovrLBAthInfo">
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>LOC</div>
                                                                                <div>{result.points}</div>
                                                                            </div>
                                                                            <div className="botOvrContainer">
                                                                            <div style={{fontSize: "9px"}}>Time</div>
                                                                            {this.state.course_type != 'overall_results' && result.tiebreak_time != '' && result.tiebreak_time != null ? <div className="">{result.tiebreak_time.substr(3)}</div> : <div></div>}
                                                                            </div>
                                                                            {this.state.course_type == "open_results" || this.state.course_type == "hybrid_results" ?
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>Fails</div>
                                                                                <div>{result.total_fails}</div>
                                                                            </div> : null}
                                                                        </div>
                                                                    })
                                                                : <div>{this.state.run_order.filter(obj=> obj.athlete_id == item.athlete_id).map((ro, index) =>{return <div style={{marginLeft: "20px"}}>Run Order - {ro.run_order}</div>})}</div>
                                                                }
                                                                    



                                                                    
                                                                </div>
                                                    </div>
                                                }
                                            })
                                            : this.state.overall_results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).length >= this.state.upper_limit ?
                                        this.state.overall_results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(this.state.upper_limit - 1, this.state.upper_limit).map((item, index) => {
                                            if (item.gender == this.state.scoreboard_gender) {
                                                return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "bubbleAthlete botLBAthlete"}>
                                                    <div className="botLBAthTitle">
                                                        {/* <div className="botLBName">{item.athlete_rank}.</div> */}
                                                        <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 9)}</div></div>
                                                                    <div className="botLBName"><span style={{fontSize: "10px"}}>Pts.</span> {item.points}</div>
                                                        {/* <div className="botLBName">{item.points}</div>     */}
                                                    </div>
                                                    <div className="botLBAthInfo" style={{justifyContent: "flex-start"}}>
                                                                    
                                                                    {item.hybrid_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">H</div>
                                                                        <div className="botLBLab">{item.hybrid_rank}</div>
                                                                    </div> : null}
                                                                    {item.speed_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">S</div>
                                                                        <div className="botLBLab">{item.speed_rank}</div>
                                                                    </div>  : null}
                                                                    {item.burnout_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">B</div>
                                                                        <div className="botLBLab">{item.burnout_rank}</div>
                                                                    </div>  : null}
                                                                    
                                                                    
                                                                    
                                                                    {this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).length > 0 ? 
                                                                    this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).map((result, index) =>{
                                                                        return <div className="ovrLBAthInfo">
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>LOC</div>
                                                                                <div>{result.points}</div>
                                                                            </div>
                                                                            <div className="botOvrContainer">
                                                                            <div style={{fontSize: "9px"}}>Time</div>
                                                                            {this.state.course_type != 'overall_results' && result.tiebreak_time != '' && result.tiebreak_time != null ? <div className="">{result.tiebreak_time.substr(3)}</div> : <div></div>}
                                                                            </div>
                                                                            {this.state.course_type == "open_results" || this.state.course_type == "hybrid_results" ?
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>Fails</div>
                                                                                <div>{result.total_fails}</div>
                                                                            </div> : null}
                                                                        </div>
                                                                    })
                                                                : <div>{this.state.run_order.filter(obj=> obj.athlete_id == item.athlete_id).map((ro, index) =>{return <div style={{marginLeft: "20px"}}>Run Order - {ro.run_order}</div>})}</div>
                                                                }
                                                                    



                                                                    
                                                                </div>
                                                </div>
                                            }
                                        })
                                            
                                            : this.state.overall_results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).length >= 5 ?
                                            this.state.overall_results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(-1).map((item, index) => {
                                                if (item.gender == this.state.scoreboard_gender) {
                                                    return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "bubbleAthlete botLBAthlete"}>
                                                        <div className="botLBAthTitle">
                                                        <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 9)}</div></div>
                                                                    <div className="botLBName"><span style={{fontSize: "10px"}}>Pts.</span> {item.points}</div>
                                                            {/* <div className="botLBName">{item.points}</div>     */}
                                                        </div>
                                                        <div className="botLBAthInfo" style={{justifyContent: "flex-start"}}>
                                                                    
                                                                    {item.hybrid_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">H</div>
                                                                        <div className="botLBLab">{item.hybrid_rank}</div>
                                                                    </div> : null}
                                                                    {item.speed_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">S</div>
                                                                        <div className="botLBLab">{item.speed_rank}</div>
                                                                    </div>  : null}
                                                                    {item.burnout_rank > 0 ?
                                                                    <div className="botOvrContainer">
                                                                        <div className="botLBNum">B</div>
                                                                        <div className="botLBLab">{item.burnout_rank}</div>
                                                                    </div>  : null}
                                                                    
                                                                    
                                                                    
                                                                    {this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).length > 0 ? 
                                                                    this.state.results.filter(obj=> obj.athlete_id == item.athlete_id).map((result, index) =>{
                                                                        return <div className="ovrLBAthInfo">
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>LOC</div>
                                                                                <div>{result.points}</div>
                                                                            </div>
                                                                            <div className="botOvrContainer">
                                                                            <div style={{fontSize: "9px"}}>Time</div>
                                                                            {this.state.course_type != 'overall_results' && result.tiebreak_time != '' && result.tiebreak_time != null ? <div className="">{result.tiebreak_time.substr(3)}</div> : <div></div>}
                                                                            </div>
                                                                            {this.state.course_type == "open_results" || this.state.course_type == "hybrid_results" ?
                                                                            <div className="botOvrContainer">
                                                                                <div style={{fontSize: "9px"}}>Fails</div>
                                                                                <div>{result.total_fails}</div>
                                                                            </div> : null}
                                                                        </div>
                                                                    })
                                                                : <div>{this.state.run_order.filter(obj=> obj.athlete_id == item.athlete_id).map((ro, index) =>{return <div style={{marginLeft: "20px"}}>Run Order - {ro.run_order}</div>})}</div>
                                                                }
                                                                    



                                                                    
                                                                </div>
                                                    </div>
                                                }
                                            })
                                        : null }


                                            </div>
                                        </div>
                                        : null}
                                </div>


{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
Overall Leaderboard End
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
Course Leaderboard Start
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

                                <div style={{marginTop: "50px"}}>
                                    {this.state.course_type != 'overall_results' ?

                                        <div className="botLBContain">
                                            {/* <div className="" style={{ marginTop: "10px" }}>
                                                <div style={{ textAlign: "left" }}>
                                                    <div className="LCscoreboardSubTitle" style={{ color: "#94C2E4" }}>
                                                        {this.state.scoreboard_gender == 'Male' ? <span>Men</span> : this.state.scoreboard_gender == 'Female' ? <span>Women</span> : null}
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="bottomLBFlexRow">
                                                {this.state.results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(0, 4).map((item, index) => {
                                                        if (item.gender == this.state.scoreboard_gender) {
                                                            return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "nonActiveAthlete botLBAthlete"}>
                                                                <div className="botLBAthTitle">
                                                                    {/* <div className="botLBName">{item.athlete_rank}.</div> */}
                                                                    <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 10)}</div></div>
                                                                    {/* <div className="botLBName">{item.points}</div>     */}
                                                                </div>
                                                                <div className="botLBAthInfo">
                                                                    <div style={{width: "33%"}}>
                                                                        <div style={{fontSize: "9px"}}>LOC</div>
                                                                        <div>{item.points}</div>
                                                                    </div>
                                                                    <div>
                                                                    <div style={{fontSize: "9px"}}>Time</div>
                                                                    {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <div className="">{item.tiebreak_time.substr(3)}</div> : <div></div>}
                                                                    </div>
                                                                    <div style={{width: "33%"}}>
                                                                        <div style={{fontSize: "9px"}}>Fails</div>
                                                                        <div>{item.total_fails}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    })}

                                            {this.state.current_place <= this.state.upper_limit && this.state.current_place > 4 ?
                                            this.state.results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(this.state.current_place-1, this.state.current_place).map((item, index) => {
                                                if (item.gender == this.state.scoreboard_gender) {
                                                    return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "bubbleAthlete botLBAthlete"}>
                                                        <div className="botLBAthTitle">
                                                            {/* <div className="botLBName">{item.athlete_rank}.</div> */}
                                                            <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 10)}</div></div>
                                                            {/* <div className="botLBName">{item.points}</div>     */}
                                                        </div>
                                                        <div className="botLBAthInfo">
                                                            <div style={{width: "33%"}}>
                                                                <div style={{fontSize: "9px"}}>LOC</div>
                                                                <div>{item.points}</div>
                                                            </div>
                                                            <div>
                                                            <div style={{fontSize: "9px"}}>Time</div>
                                                            {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <div className="">{item.tiebreak_time.substr(3)}</div> : <div></div>}
                                                            </div>
                                                            <div style={{width: "33%"}}>
                                                                <div style={{fontSize: "9px"}}>Fails</div>
                                                                <div>{item.total_fails}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            })
                                            : this.state.results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).length >= this.state.upper_limit ?
                                        this.state.results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(this.state.upper_limit - 1, this.state.upper_limit).map((item, index) => {
                                            if (item.gender == this.state.scoreboard_gender) {
                                                return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "bubbleAthlete botLBAthlete"}>
                                                    <div className="botLBAthTitle">
                                                        {/* <div className="botLBName">{item.athlete_rank}.</div> */}
                                                        <div className="botLBName"><div><span style={{marginRight: "10px"}}>{item.athlete_rank}.</span>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 10)}</div></div>
                                                        {/* <div className="botLBName">{item.points}</div>     */}
                                                    </div>
                                                    <div className="botLBAthInfo">
                                                        <div style={{width: "33%"}}>
                                                            <div style={{fontSize: "9px"}}>LOC</div>
                                                            <div>{item.points}</div>
                                                        </div>
                                                        <div>
                                                        <div style={{fontSize: "9px"}}>Time</div>
                                                        {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <div className="">{item.tiebreak_time.substr(3)}</div> : <div></div>}
                                                        </div>
                                                        <div style={{width: "33%"}}>
                                                            <div style={{fontSize: "9px"}}>Fails</div>
                                                            <div>{item.total_fails}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        })
                                            
                                            : this.state.results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).length >= 5 ?
                                            this.state.results.sort((a, b) => a.athlete_rank - b.athlete_rank).filter(gen => gen.gender == this.state.scoreboard_gender).slice(-1).map((item, index) => {
                                                if (item.gender == this.state.scoreboard_gender) {
                                                    return <div className={this.state.athlete_id == item.athlete_id ? "botLBAthlete activeAthlete" : "bubbleAthlete botLBAthlete"}>
                                                        <div className="botLBAthTitle">
                                                            <div className="botLBName">{item.athlete_rank}.</div>
                                                            <div className="botLBName"><div style={{ width: '120px' }}>{item.first_name.slice(0, 1)}. {item.last_name.slice(0, 10)}</div></div>
                                                            {/* <div className="botLBName">{item.points}</div>     */}
                                                        </div>
                                                        <div className="botLBAthInfo">
                                                            <div style={{width: "33%"}}>
                                                                <div style={{fontSize: "9px"}}>LOC</div>
                                                                <div>{item.points}</div>
                                                            </div>
                                                            <div>
                                                            <div style={{fontSize: "9px"}}>Time</div>
                                                            {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <div className="">{item.tiebreak_time.substr(3)}</div> : <div></div>}
                                                            </div>
                                                            <div style={{width: "33%"}}>
                                                                <div style={{fontSize: "9px"}}>Fails</div>
                                                                <div>{item.total_fails}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            })
                                        : null }


                                            </div>
                                        </div>
                                        : null}
                                </div>


                            </div>
                            : null}
                    </div>

                </div>

                {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
End Athlete Result Section
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

                {/* End of Leaderboard Section xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
            </div>
        );
}
    }
}

export default BottomLiveLeaderboard;