import React, {Component} from 'react';
import NSNHeader from "../components/header";
import NSNFooter from "../components/footer";
import '../style/main.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock, faCalendarDay} from '@fortawesome/free-solid-svg-icons';
// import actionPhoto from '../static/digangi_pic.jpg';
// import gymPhoto from '../static/chicago_gym.jpg';
// import promoPoster from '../static/promoPoster.png';
// import { connect } from 'react-redux';
// import {setUserToReduxState, logoutUserFromReduxState} from '../actions/auth_actions';
// import {verifyUser} from '../functions/auth_functions';
import Autocomplete from '../components/autocomplete';
// import VideoPlayer from '../components/video_player.js';
import {getFeaturedVideos, getSearchText} from '../api/api.js';
import loadingImg from '../static/NSC-Blue-PNG-Outline.png';
import {getAllVideos} from '../api/api.js';
// import IntegrationReactSelect from '../components/react_autocomplete';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';




library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock,faCalendarDay);


class Videos extends Component {

  state={
    openFree: false,
    openPremium: false,
    videoArr: [],
    loading: true,
    // mainVideo: {video_link: '361937353'},
    mainVideo: {},
    searchArr: [],
    openDialog: false,
    dialogTitle: "", 
    dialogMsg: "",
  }

//   videoJsOptions = {
//     autoplay: false,
//     playbackRates: [0.5, 1, 1.25, 1.5, 2],
//     width: 720,
//     height: 300,
//     controls: true,
//     sources: [
//       {
//         // src: 'https://player.vimeo.com/external/361937353.hd.mp4?s=40d6d0ecdb387cfc8ae68c83738c1ea3ff13f63e&profile_id=175',
//         src: "https://player.vimeo.com/external/361937353.m3u8?s=3cd4a93543378194d1e00e0284490ce6c123ada4",
//         // type: 'video/mp4',
//         type: "application/x-mpegURL"
//       },
//     ],
//   };

  dynamicBackground = (imgSrc) => ({
    backgroundImage: `url(${imgSrc})`
  })

  formatDate(val){
    var year = val.split("-")[0];
    var month = val.split("-")[1];  
    var day = val.split("-")[2];
    return (month + "/" + day + "/" + year);
  }

    
  toggleDiv(type){
    var divState;
    if(this.state[type]==true){
      divState = false;
    }else{
      divState = true;
    }
    this.setState({[type]: divState});
  }

  goTo(str , id, type){
        //   if((premium == 1 && this.props.auth.user.sub_status == 'active') || premium==0){
        this.props.history.push({
            pathname: '/' + str,
            search: '?type=' + type + "&id=" + id,
            state: { type: type, id: id }
        })
        // }else{
        //     this.setState({openDialog: true, dialogTitle: "Attention!", dialogMsg: 'This video requires a UNX base subscription. You can upgrade your subscription for $5.99/month'});
        // }
}

upgradeLink = () => {
    this.props.history.push('/checkout');
}

searchChoice(str, id, type){
    this.goTo(str, id, type);
}

closeDialog = (type) =>{
    this.setState({ openDialog: false, dialogTitle: "", dialogMsg: "" });
}


// componentWillMount(){
//     verifyUser(this.props.auth).then(resp=>{
//     }).catch(err=>{
//         console.log(err);
//         this.props.logoutUserFromReduxState();
//     });
//   }



  updateMainVideo(vid){
                this.setState({mainVideo: vid})

  }



  componentDidMount(){
    console.log(this);

    getAllVideos().then(resp=>{
        console.log(resp);
        this.setState({mainVideo: resp.videos[0], videoArr: resp.videos, loading: false});
    }).catch(err=>{
        console.log(err);
    })
  }

    render() {
        if(this.state.loading == true){
            return (<div className="mainDiv" style={{height: "100vh"}}>
                <NSNHeader title='' link='' linkTitle=''></NSNHeader>
                <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                    <div className="pulseImg">
                    <img src={loadingImg} width="200px" height="auto" />
                    </div>
                    </div>
                    </div>
            )
        }else{
      return (
          <div className="mainDiv">
            <NSNHeader title='' link='' linkTitle=''></NSNHeader>
            <div className="videoPageContainer" style={{marginTop: "10px"}}>
            <div className="flex-dash-main">
                <div className="mainVideoContainer">

                    <div class='embed-container'>
                        <iframe className="mainVideo" src={"https://www.youtube.com/embed/" + this.state.mainVideo.url + "?wmode=transparent"} wmode="Opaque" width="100%" height="auto" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                    </div>

                </div>
                <div className="searchVideos">
                    <div>
                        <Autocomplete
                            suggestions={this.state.searchArr}
                           
                        />
                        {/* <IntegrationReactSelect></IntegrationReactSelect> */}
      </div>
                    <div className="darkContainerCol">
                    <div style={{color: "rgb(175,175,175", fontWeight: "bold", padding: "5px"}}>Trending</div>
                    {this.state.videoArr.slice(0,4).map((item, index) => {
                            return <div className="videoSummaryChoice" onClick={e=>this.updateMainVideo(item)}>
                            <div><img className="roundedSummaryImage" src={item.thumbnail_url} alt="actionPhoto" width="130px" height="auto"/></div>
                            <div className="videoSummaryMargins">
                                <div className="videoSummmaryTitles">{item.title}</div>
                                {/* <div className="videoSummmarySubTitles">{item.video_desc}</div> */}
                            </div>
                        </div>  
                    })}
                    
                    </div>
                </div>
              </div>

              <div id="trendingMobile">
              <div className="darkContainerRow">
              <div className="flex-container-row" style={{justifyContent: "space-between"}}>
                  <div className="darkContainerTitle">Trending</div>
                  {/* <div className="darkContainerTitle" style={{color: "rgb(77,198,231)", cursor: "pointer"}} onClick={e=>this.goTo('video_collection', 1, 'video_type_id', 1, 0)}>See All</div> */}
                  </div>
                    <div className="flex-container-row">
                    {this.state.videoArr.slice(0,4).map((item, index) => {
                        return <div className="videoMainChoice">
                        <div className="imgMainContainer">
                                
                                {/* <img className="roundedMainImage" src={actionPhoto} alt="actionPhoto" width="250px" height="auto"/> */}

                                <div className="aspect-ratio-box" style={this.dynamicBackground(item.thumbnail_url)} onClick={e=>this.goTo('video_page', item.video_id, item.video_type)}>
                                {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                        <div className="flexbox-centering">
                                        <div className="viewport-sizing">
                                            {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                            <div className="videoMainMargins">
                                <div className="videoMainTitles">{item.title}</div>
                                {/* <div className="videoMainSubTitles">{item.video_desc}</div> */}
                                {/* {item.active == 1 ? <div className="videoMainSubTitles">{this.formatDate(item.date_added.split("T")[0])}</div> */}
                                {/* : <div className="videoMainSubTitles">Coming Soon | {this.formatDate(item.release_date.split("T")[0])}</div>} */}
                            </div>
                        </div>
                        })}

                    </div>
                </div>
                </div>

              <div className="darkContainerRow">
              <div className="flex-container-row" style={{justifyContent: "space-between"}}>
                  <div className="darkContainerTitle">NSC Competitions</div>
                  <div className="darkContainerTitle" style={{color: "rgb(77,198,231)", cursor: "pointer"}} onClick={e=>this.goTo('video_collection', 1, 'competition')}>See All</div>
                  </div>
                    <div className="flex-container-row">
                    {this.state.videoArr.filter(vid=>vid.playlist=="competitions").map((item, index) => {
                        if(index < 4){
                        return <div className="videoMainChoice">
                        <div className="imgMainContainer">
                                
                                {/* <img className="roundedMainImage" src={actionPhoto} alt="actionPhoto" width="250px" height="auto"/> */}

                                <div className="aspect-ratio-box" style={this.dynamicBackground(item.thumbnail_url)} onClick={e=>this.goTo('video_page', item.video_id, item.video_type)}>
                                {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                        <div className="flexbox-centering">
                                        <div className="viewport-sizing">
                                        {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                            <div className="videoMainMargins">
                                <div className="videoMainTitles">{item.title}</div>
                                <div className="videoMainSubTitles">{item.video_desc}</div>
                                {/* {item.active == 1 ? <div className="videoMainSubTitles">{this.formatDate(item.date_added.split("T")[0])}</div> */}
                                {/* : <div className="videoMainSubTitles">Coming Soon | {this.formatDate(item.release_date.split("T")[0])}</div>} */}
                            </div>
                        </div>
                        }
                        })}

                    </div>
                </div>

                <div className="darkContainerRow">
                <div className="flex-container-row" style={{justifyContent: "space-between"}}>
                  <div className="darkContainerTitle">Athlete Finals Highlights</div>
                  <div className="darkContainerTitle" style={{color: "rgb(77,198,231)", cursor: "pointer"}} onClick={e=>this.goTo('video_collection', 2, 'athlete_highlights')}>See All</div>
                  </div>
                    <div className="flex-container-row">

                    {this.state.videoArr.filter(vid=>vid.playlist=="athlete_highlights").map((item, index) => {
                        if(index < 4){
                        return <div className="videoMainChoice">
                        <div className="imgMainContainer">
                                
                                {/* <img className="roundedMainImage" src={actionPhoto} alt="actionPhoto" width="250px" height="auto"/> */}

                                <div className="aspect-ratio-box" style={this.dynamicBackground(item.thumbnail_url)} onClick={e=>this.goTo('video_page', item.video_id, item.video_type)}>
                                {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                        <div className="flexbox-centering">
                                        <div className="viewport-sizing">
                                        {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                            <div className="videoMainMargins">
                                <div className="videoMainTitles">{item.title}</div>
                                <div className="videoMainSubTitles">{item.video_desc}</div>
                                {/* {item.active == 1 ? <div className="videoMainSubTitles">{this.formatDate(item.date_added.split("T")[0])}</div>
                                : <div className="videoMainSubTitles">Coming Soon | {this.formatDate(item.release_date.split("T")[0])}</div>} */}
                            </div>
                        </div>
                        }
                        })}

                    </div>
                </div>

                <div className="darkContainerRow">
                <div className="flex-container-row" style={{justifyContent: "space-between"}}>
                  <div className="darkContainerTitle">NSC Shorts</div>
                  <div className="darkContainerTitle" style={{color: "rgb(77,198,231)", cursor: "pointer"}} onClick={e=>this.goTo('video_collection', 3, 'shorts')}>See All</div>
                  </div>
                    <div className="flex-container-row">

                    {this.state.videoArr.filter(vid=>vid.playlist=="shorts").map((item, index) => {
                        if(index < 4){
                        return <div className="videoMainChoice">
                        <div className="imgMainContainer">

                                <div className="aspect-ratio-box" style={this.dynamicBackground(item.thumbnail_url)} onClick={e=>this.goTo('video_page', item.video_id, item.video_type)}>
                                {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                        <div className="flexbox-centering">
                                        <div className="viewport-sizing">
                                        {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                            <div className="videoMainMargins">
                                <div className="videoMainTitles">{item.title}</div>
                                <div className="videoMainSubTitles">{item.video_desc}</div>
                                {item.active == 1 ? <div>
                                    {/* <div className="videoMainSubTitles">{item.description.split(" | ")[0]}</div> */}
                                    {/* <div className="videoMainSubTitles">{item.description.split(" | ")[1]}</div> */}
                                    </div>
                                : <div>
                                    <div className="videoMainSubTitles">{item.video_desc}</div>
                                    <div className="videoMainSubTitles">Coming Soon | {this.formatDate(item.release_date.split("T")[0])}</div>
                                </div>}
                            </div>
                        </div>
                        }
                        })}
                    </div>
                </div>
              
              </div>
              <NSNFooter title='' link='' linkTitle=''></NSNFooter>
              {/* <Dialog
                open={this.state.openDialog}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                            {this.state.dialogMsg}      
                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <button type="button" onClick={this.upgradeLink} className="stripeButton">
                                    Upgrade
                    </button>
                            <button type="button" onClick={this.closeDialog} className="stripeButton">
                                    Close
                    </button>
                            </DialogActions>
                        </Dialog> */}
          </div>
      );
    }
    }
  }


export default Videos;
