import React, { Component } from 'react';
import '../style/main.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";

class RulesPage extends Component {

    state = {
    
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
                    <div>
                        <HeroImg page="rules" title="NSC Rules"></HeroImg>
                    </div>

                    <div className="rulesContainer">
                    <div className="NSCTitle" style={{fontSize: "20px"}}>Summary</div>

                    <p>NSC competitions are designed to test athletes on the 3 main disciplines of Ninja: Technique (Hybrid), 
                        Speed, and Burnout. NSC competitions start with an open round that anyone can sign up for, Athletes 
                        must be at least 13 years old by the day of competition to compete. The top 15 Male and Female athletes 
                        from the Open round advance to the Finals round where they will run 3 more courses (Hybrid, Speed, 
                        Burnout) and the winner is decided by adding points from their placement across all three courses. 
                        For a full rules breakdown see below:</p>

                        <div className="NSCTitle" style={{fontSize: "20px"}}>Retry System</div>

<p>NSC competitions use a rule set called the Retry System for the Open, Hybrid, and Speed rounds. Athletes are given 2 retries on every obstacle on the course and athletes must complete the obstacle to advance. Each time an athlete fails an obstacle they are given a “fail” which will affect their score. If an obstacle is failed 3 times the athlete’s run is over. When an athlete fails an obstacle they must return to the designated start platform for the obstacle and must wait a designated Reset Time and will receive the go-ahead from the judge when the reset time is over. Reset time is different for every course and is decided by the time it takes to reset the longest obstacle on the course.</p>

<div className="NSCTitle" style={{fontSize: "20px"}}>Open Round</div>

<p>All registered athletes compete in the Open Round. The open round utilizes the Retry System. Placement for the Open Round is determined by the following:</p>

<ol>
<li>Most obstacles cleared</li>
<li>Total number of fails</li>
<li>Time to complete the Last Obstacle Completed (LOC)</li>
<li>The top 15 Men and top 15 Women in the Open Round advance to the Final Round</li>
</ol>


<div className="NSCTitle" style={{fontSize: "20px"}}>Finals Round</div>

<p>Athletes will start with a Hybrid course run in the finals and each round the bottom X number of athletes will drop out of the competition as described below. Poijts for the Finalsround are cumulative across all 3 rounds. </p>

<p>Hybrid Course: The hybrid course is a longer course of 6-15 obstacles combining elements of technical skill, endurance, and speed, it is modeled after the traditional course style that Ninja was founded on. This course utilizes the retry system. 
    The top 12 athletes from the Hybrid round will advance to the next round and the bottom 3 will drop out of the competition.
</p>

<p>Placement</p>

<ol>
<li>Most obstacles cleared</li>
<li>Total number of fails</li>
<li>Time to complete the Last Obstacle Completed (LOC)</li>
</ol>

<p>Speed Course: The speed course is comprised of easier obstacles focused on speed and efficiency with average course times around 20-60 seconds. This course also uses the retry system.
    The top 8 athletes with their combined scores from the Hybrid and Speed rounds advance to the next round.
</p>

<p>Placement</p>

<ol>
<li>Most Obstacles Cleared</li>
<li>Time to complete LOC</li>
<li>*Fails are not counted against the athlete in the speed round</li>
</ol>

<p>Burnout Course: The top 8 athletes from the combined scores of Hybrid and Speed will compete in the Burnout. The burnout course focuses on upper body endurance, once the athlete’s feet leave the ground they do not stop until they either fail an obstacle or finish. This is the only course without the retry system and once there is a fail the run is over.</p>

<p>Placement</p>

<ol>
<li>Most Obstacles Cleared</li>
<li>Time to complete LOC</li>
</ol>

<div className="NSCTitle" style={{fontSize: "20px"}}>Overall Competition Placement</div>
<p>Placement for the Finals round is determine by the following:</p>

<ol>
<li>Points are added across all 3 courses, Hybrid, Speed, Burnout and the winner is determined by the athlete with the most points</li>
<li>Points are awarded per round based on the table below:</li>
</ol>

<table className="scoreTable">
    <thead>
        <th>Place</th><th>Hybrid</th><th>Speed</th><th>Burnout</th>
    </thead>
    <tbody>
        <tr><td>1</td><td>30</td><td>30</td><td>30</td></tr>
        <tr><td>2</td><td>28</td><td>27</td><td>26</td></tr>
        <tr><td>3</td><td>26</td><td>24</td><td>22</td></tr>
        <tr><td>4</td><td>24</td><td>21</td><td>18</td></tr>
        <tr><td>5</td><td>22</td><td>18</td><td>14</td></tr>
        <tr><td>6</td><td>20</td><td>15</td><td>10</td></tr>
        <tr><td>7</td><td>18</td><td>12</td><td>6</td></tr>
        <tr><td>8</td><td>16</td><td>9</td><td>2</td></tr>
        <tr><td>9</td><td>14</td><td>7</td><td></td></tr>
        <tr><td>10</td><td>12</td><td>5</td><td></td></tr>
        <tr><td>11</td><td>10</td><td>3</td><td></td></tr>
        <tr><td>12</td><td>8</td><td>1</td><td></td></tr>
        <tr><td>13</td><td>6</td><td></td><td></td></tr>
        <tr><td>14</td><td>4</td><td></td><td></td></tr>
        <tr><td>15</td><td>2</td><td></td><td></td></tr>
    </tbody>
</table>

<p>TIE-BREAKING</p>

<ol>
<li>If two athletes are tied with the same points after all three courses, only the athlete’s top two placements will be added together to determine the winner.</li>
<li>If the athlete’s top 2 placements still result in a tie, only the athlete’s top placement will be compared to determine the winner.</li>
<li>If the top placement still results in a tie, the tiebreaker will be determined by the athlete who placed higher in the Open Round.</li>
</ol>
                    </div>


                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}

export default RulesPage;