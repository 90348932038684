import React, { Component } from 'react';
import NSNHeader from "../components/header";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Column, Row } from 'simple-flexbox';
import '../style/score.css';
import '../style/main.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { postCourse, getComps } from '../api/api';
import {convertMilliseconds} from '../functions/timeFormat.js';

library.add(faPlusCircle);


class CreateCourse extends Component {


    state = {
        open: false,
        obstacleArr: [],
        dialogTitle: '',
        dialogMsg: '',
        pointsArr: [],
        compsArr: [],
        competition: '',
        courseType: '',
        courseMin: '',
        courseSec: '',
        courseMs: '',
        resetMin: '',
        resetSec: '',
        resetMs: ''
    };


    closeDialog = () => {
        this.setState({ open: false });
    }

    confirmSubmission() {
        this.setState({ open: true, dialogTitle: 'Submit Finalized Course', dialogMsg: 'Are you sure you want to submit this course? Once you submit the course yo uwill not be able to edit it.' });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleObstacleChange = (name, idx) => evt => {

        const newObstacles = this.state.obstacleArr.map((item, oidx) => {
            if (idx !== oidx) {
                return item;
            } else if (name == "points") {
                const pArr = [];
                for (var i = 0; i < evt.target.value; i++) {
                    pArr.push({ point: i + 1, description: '' });
                }
                return { ...item, [name]: evt.target.value, ["pointsArr"]: pArr };
            } else {
                return { ...item, [name]: evt.target.value };
            }

        });

        this.setState({ obstacleArr: newObstacles });
    };

    //   handlePointChange = (name,pidx, idx) => evt => {
    //     const newObstacles = this.state.obstacleArr.map((item, oidx)=>{ 
    //     if(idx !== oidx){
    //         return item;
    //     }else{
    //         const newPoints = item.pointsArr.map((pointitem, pointidx) => {
    //         if (pidx !== pointidx) return pointitem;
    //         return { ...pointitem, [name]: evt.target.value };
    //         });
    //         return{...item, ["pointsArr"]: newPoints};
    //     }
    // });
    //     this.setState({ obstacleArr: newObstacles });
    //   };

    addObstacle() {
        var newArr = this.state.obstacleArr.slice();
        newArr.push({ obstacle: '', points: '1', pointsArr: [], setupStr: '' });
        this.setState({ obstacleArr: newArr, pointsArr: [], setupStr: '' });
    }


    submitCourse() {
        var courseTime = convertMilliseconds(this.state.courseMin, this.state.courseSec, this.state.courseMs);
        var resetTime = convertMilliseconds(this.state.resetMin, this.state.resetSec, this.state.resetMs);
        console.log(courseTime);
        console.log(resetTime);
        const newObstacles = this.state.obstacleArr.map((item, oidx) => {
            return { ...item, ["obstacle"]: item.obstacle.replace(/'/g, "\\'"), ["pointsArr"]: JSON.stringify(item.pointsArr) };
        });
        console.log(newObstacles);
        const ObstacleArr = JSON.stringify(newObstacles);

        postCourse(this.state.competition, this.state.courseType, courseTime, resetTime, ObstacleArr).then(resp => {
            console.log(resp);
            if ("affectedRows" in resp.request) {
                this.props.history.push('/admin');
            }
        });
    }




    componentDidMount() {
        console.log(this);
        getComps().then(resp => {
            console.log(resp);
            this.setState({ compsArr: resp.comps });
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return <div className="mainDiv">
            <NSNHeader title='Create New Course' link='' linkTitle=''></NSNHeader>

            <div className="pageContainer">
                <div className="darkCard">

                    <div className="darkCardTitle">Create a New Course</div>
                    <div className="flex-row" style={{ alignItems: "center" }}>
                        <div className="fieldLabel">
                            Select a competition
                        </div>
                        <div className="field">
                            <FormControl required>
                                <InputLabel>Competition</InputLabel>
                                <Select style={{ width: "200px" }} value={this.state.competition} onChange={this.handleChange('competition')}>
                                    {this.state.compsArr.map((item, index) => {
                                        return <MenuItem value={item.comp_id}>{item.gym_name} - {item.comp_date.split('T')[0]}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="flex-row" style={{ alignItems: "center" }}>
                        <div className="fieldLabel">
                            Select course type
                        </div>
                        <div className="field">
                            <FormControl required>
                                <InputLabel>Competition</InputLabel>
                                <Select style={{ width: "200px" }} value={this.state.courseType} onChange={this.handleChange('courseType')}>
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Speed">Speed</MenuItem>
                                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                                    <MenuItem value="Burnout">Burnout</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="flex-row" style={{ alignItems: "center", marginTop: "15px" }}>
                        <div>
                            <div style={{ color: "#dadada" }}>Course Time Limit</div>
                            <div style={{ marginTop: "10px", color: "#dadada" }}>
                                <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="min" value={this.state.courseMin} onChange={this.handleChange('courseMin')} /></span>
                                <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>:</span>
                                <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="sec" value={this.state.courseSec} onChange={this.handleChange('courseSec')} /></span>
                                <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>.</span>
                                <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="ms" value={this.state.courseMs} onChange={this.handleChange('courseMs')} /></span>
                            </div>
                        </div>
                    </div>

                    <div className="flex-row" style={{ alignItems: "center", marginTop: "15px" }}>
                        <div>
                            <div style={{ color: "#dadada" }}>Reset Time</div>
                            <div style={{ marginTop: "10px", color: "#dadada" }}>
                                <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="min" value={this.state.resetMin} onChange={this.handleChange('resetMin')} /></span>
                                <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>:</span>
                                <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="sec" value={this.state.resetSec} onChange={this.handleChange('resetSec')} /></span>
                                <span style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: "18px", fontWeight: "bold" }}>.</span>
                                <span><input style={{ width: '50px' }} className='skillInput' type="number" placeholder="ms" value={this.state.resetMs} onChange={this.handleChange('resetMs')} /></span>
                            </div>
                        </div>
                    </div>


                </div>

                {this.state.obstacleArr.map((item, index) => {
                    return <Row>
                        <div class="courseObstacleBox">
                            <Row horizontal="spaced" vertical="center">
                                <div style={{ fontSize: "28px;" }}>{index + 1}.</div>
                                <div>
                                    <input className="courseInputBox" type="text" placeholder={`Obstacle`} value={item.obstacle} onChange={this.handleObstacleChange('obstacle', index)} />
                                </div>
                                <div>
                                    <div>
                                        <input className="courseInputBox" style={{ width: "20px" }} type="text" disabled="true" placeholder={`#`} value={item.points} onChange={this.handleObstacleChange('points', index)} />
                                    </div>
                                    <div style={{ fontSize: ".8em", color: "rgb(125,125,125)" }}> Pts.</div>
                                </div>
                            </Row>
                            {/* <Row>
                                        <div style={{width: "100%"}}>
                                         <textarea className="courseInputBox" style={{width: "100%"}} type="text" placeholder={`Setup Notes`} value={item.setupStr} onChange={this.handleObstacleChange('setupStr' , index)}/>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div>
                                        {item.pointsArr.map((pitem, pindex) => {
                                            return <div>
                                                <div>
                                                    <span>{pitem.point}. </span><input className="courseInputBox" style={{margin: "10px"}} type="text" placeholder={`Ex: Touch 1st bar`} value={item.description} onChange={this.handlePointChange('description' , pindex, index)}/>
                                                </div>
                                            </div>
                                        })}
                                        </div>
                                    </Row> */}
                        </div>
                    </Row>
                })}
                <Row>
                    <div className="courseObstacleBox" onClick={e => this.addObstacle()}>
                        <FontAwesomeIcon icon="plus-circle" /> Add New Obstacle
                    </div>
                </Row>
                <Row>
                    <div style={{ width: "100%" }}>
                        {this.state.obstacleArr.length > 0 ?
                            <button style={{ width: "100%" }} className='submitBtn' onClick={e => this.confirmSubmission()}>Submit</button> : null}
                    </div>
                </Row>
            </div>


            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='submitBtn' onClick={e => this.submitCourse()}>Submit</Button>
                    <Button type='submit' onClick={this.closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

export default CreateCourse;