import React, { Component } from 'react';
import '../style/main.css';
import Logo from '../static/NSN_Small_Logo.png';
import '../style/main.css';
import { withRouter, Route, Link } from 'react-router-dom';
// import { connect } from 'react-redux';


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faChevronDown, faCaretDown} from '@fortawesome/free-solid-svg-icons';



library.add(faUser, faBars, faChevronDown, faCaretDown);

class NSNHeader extends Component { 

    state={
        menuOpen: false,
        toggleDropdown: false
    }

    goTo(str){
        this.props.history.push('/' + str);
    }

    myFunction() {
        console.log('Clicked');
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
          x.className += " responsive";
        } else {
          x.className = "topnav";
        }
      }
          

    componentDidMount(){
        console.log(this);
    }

    render() {
        return (
            // <div className="headerContainer">
            // <div className="headerSection" style={{justifyContent: "space-evenly", backgroundColor: "rgb(20,24,32)", alignItems: "center"}}>
            //     <div className="menuItems" style={{width: "100%", marginLeft: "0px", alignItems: "center", justifyContent: "flex-start"}}>
            //         {/* <div>
            //             <Link className="headerLink" to="/about_us" style={{fontWeight: "bold"}}>About Us</Link>
            //         </div> */}
            //         {/* <div>
            //             <Link className="headerLink" to="/unx_championship_series" style={{fontWeight: "bold"}}>UNX Championship Series</Link>
            //         </div> */}
            //         <div>
            //         <Link className="headerLink" to="/"><img alt='nsn_logo' src={Logo} width="150" height="auto"/></Link>
            //         </div>
            //         <div style={{textAlign: "center"}}>
            //         <Link className="headerLink" to="/public_leaderboard" style={{fontWeight: "bold"}}>Run Order and Results</Link>
            //         </div>
            //         <div>
            //             <a className="headerLink" className="headerLink" href="https://ninjasportnetwork.bigcartel.com/" target="_blank" style={{fontWeight: "bold"}}>Store</a>
            //         </div>
            //         {/* <div>
            //             <a className="headerLink" href="https://www.youtube.com/channel/UCeidwTomRFqkERADxH2iYpA" style={{fontWeight: "bold"}}>YouTube</a>
            //         </div> */}
            //     </div>
            // </div>

            // </div>

            <div>
                <div className="topnav" id="myTopnav">
                    <div>
                        <Link style={{padding: "0px", margin:"0px 30px 0px 0px"}} to="/"><img alt='nsn_logo' src={Logo} width="100" height="auto"/></Link>
                    </div>
                    {/* <a href="#home">Videos</a> */}
                    <div className="dropdown">
                        <button className="dropbtn">Athletes 
                        <FontAwesomeIcon icon="caret-down" style={{paddingLeft: "4px"}}></FontAwesomeIcon>
                        </button>
                        <div className="dropdown-content">
                        <Link className="dropItem" to="/nsc_athlete_ranking">NSC Ranking</Link>
                        <Link className="dropItem" to="/comp_registration">Compete</Link>
                        </div>
                    </div>
                    {/* <a href="#contact">Contact</a> */}
                    <div className="dropdown">
                        <button className="dropbtn">Competitions 
                        <FontAwesomeIcon icon="caret-down" style={{paddingLeft: "4px"}}></FontAwesomeIcon>
                        </button>
                        <div className="dropdown-content">
                        <Link className="dropItem" to="/comp_registration">Register</Link>
                        <Link className="dropItem" to="/public_leaderboard">Run Order and Results</Link>
                        <Link className="dropItem" to="/youth_championship">Youth Championship</Link>
                        </div>
                    </div> 
                    {/* <div><Link to="/rules">Rules</Link></div>
                    <div><Link to="/youth_championship">Youth</Link></div> */}
                    <a href="/rules">Rules</a>
                    <a href="/youth_championship">Youth</a>
                    <a href="/videos">Videos</a>
                    <a href="/sponsors">Sponsors</a>
                    {/* <div className="dropdown">
                        <button className="dropbtn">More 
                        <FontAwesomeIcon icon="caret-down" style={{paddingLeft: "4px"}}></FontAwesomeIcon>
                        </button>
                        <div className="dropdown-content">
                        <Link className="dropItem" to="/">About</Link>
                        <Link className="dropItem" to="/">News</Link>
                        <Link className="dropItem" to="/">Partners</Link>
                        <Link className="dropItem" to="/">Contact</Link>
                        </div>
                    </div> */}
                    <a href="https://ninjasportnetworkstore.com/" target="_blank">Store</a>
                    <a href="javascript:void(0);" style={{fontSize: "15px"}} className="icon" onClick={ e => this.myFunction()}>&#9776;</a>
                    </div>
            </div>
        );
    }
}


export default (withRouter(NSNHeader));
