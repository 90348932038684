import React, {Component} from 'react';
import awsconfig from './../aws-exports'; // if you are using Amplify CLI
import Amplify, { API } from 'aws-amplify';
import {getUser} from '../api/aws/amplify_api';
import { connect } from 'react-redux';
import { verifyUser} from '../functions/auth_functions';
import {setUserToReduxState, logoutUserFromReduxState} from '../actions/auth_actions';
// import {CustomSignUp} from '../components/sign_up';
import Form from '../functions/authForm';
import NSNHeader from "../components/header";
// import '../style/clock_output.css';
import PostRunScore from "../components/postRunScore";
import queryString from 'query-string';

Amplify.configure(awsconfig);

class PostRunGraphics extends Component {
    constructor(props) {
        super(props);
    this.state = {
        ws: null,
        dataFromServer: ''
    };
}

componentDidMount() {
  var queryObj = queryString.parse(this.props.location.search);
    var compId;
    if (queryObj.compId) {
      this.setState({compId: queryObj.compId})
  }
}
    render() {
      return (
          <div className="">
            <NSNHeader title='NSN Scoreboard' link='' linkTitle=''></NSNHeader>
            <div className="pageContainer">

            <div className="clockContain">
              <div className="clock"><PostRunScore compId={this.state.compId}></PostRunScore></div>
            </div>

            </div>
          </div>
      );
    }
  }

export default PostRunGraphics;
