import React, { Component } from 'react';
import NSNHeader from "../components/header";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Column, Row } from 'simple-flexbox';
import '../style/main.css';
import '../style/score.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {postCompetition, getGyms, postGym} from '../api/api';

library.add(faPlusCircle);


class CreateCompetition extends Component {


    state = {
        open: false,
        dialogTitle: '',
        dialogMsg: '',
        compDate: '',
        gym: '',
        gymsArr: [],
        gymDialog: false,
        gym_name: '',
        gym_address: ''
    };


    closeDialog = () =>{
        this.setState({ open: false,  gymDialog: false});
    }

    confirmSubmission(){
        this.setState({open: true, dialogTitle: 'Submit Finalized Course', dialogMsg: 'Are you sure you want to submit this course? Once you submit the course yo uwill not be able to edit it.'});
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };



    addGymDialog(){
        this.setState({gymDialog: true, dialogTitle: 'Add New Gym', dialogMsg: 'Please enter the name of the gym and the address:'});
    }

    submitGym(){
        postGym(this.state.gym_name, this.state.gym_address).then(resp=>{
            console.log(resp);
                this.setState({gym_name: '', gym_address: '', gymsArr: resp.gyms, gymDialog: false});
        });
    }


    submitComp(){
        postCompetition(this.state.gym, this.state.compDate).then(resp=>{
            console.log(resp);
                this.setState({gym: '', compDate: '', open: false, gymDialog: false});
                this.props.history.push('/admin');
        });
    }

    componentDidMount(){
        console.log(this);
        getGyms().then(resp=>{
            console.log(resp);
            this.setState({gymsArr: resp.gyms});
        });
    }

    render(){
    return <div className="mainDiv">
        <NSNHeader title='Create New Competition' link='' linkTitle=''></NSNHeader>

                    <div className="pageContainer">
                        <div className="darkCard">
                            {/* Continue from here, add gym dropdown (get gym list from API) or create hidden form to add a new gym (with lambda funvtion) */}
                            <div className="darkCardTitle">Create a new Competition</div>
                            <div className="flex-row" style={{alignItems: "center"}}>
                            <div className="fieldLabel">
                                Select a gym
                            </div>
                            <div className="field">
                                <FormControl required>
                                            <InputLabel>Gym</InputLabel>
                                            <Select style={{width: "200px"}} value={this.state.gym}  onChange={this.handleChange('gym')}>
                                                {this.state.gymsArr.map((item,index)=>{
                                                    return <MenuItem value={item.gym_id}>{item.gym_name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                            </div>
                            </div>
                            <div style={{textDecoration: "underling", color: "rgb(76, 172, 51)", cursor: 'pointer'}} onClick={ e => this.addGymDialog()}>Add a New Gym</div>
                            <div className="flex-row">
                                <div className="fieldLabel">
                                    Enter Competition Date
                                </div>
                            <div className="field">
                                <input className="courseInputBox" type="text" placeholder={`Date: yyyy-mm-dd`} value={this.state.compDate} onChange={this.handleChange('compDate')}/>
                            </div>
                            </div>
                        </div>

                        <Row>
                            <div style={{width: "100%"}}>
                            {this.state.gym != '' && this.state.compDate !='' ? 
                                <button style={{width: "100%"}} className='submitBtn' onClick={ e => this.confirmSubmission()}>Submit</button> : null} 
                            </div>
                        </Row>

                    </div>

                            
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.dialogMsg}
            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button className='submitBtn' onClick={ e => this.submitComp()}>Submit</Button>
                        <Button type='submit' onClick={this.closeDialog} color="primary">
                            Close
            </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.gymDialog}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.dialogMsg}
            </DialogContentText>
            <div>
                                        <input className="courseInputBox" type="text" placeholder={`Gym Name`} value={this.state.gym_name} onChange={this.handleChange('gym_name')}/>
                                        </div>
            <div style={{width: "100%"}}>
                                         <textarea className="courseInputBox" style={{width: "100%"}} type="text" placeholder={`Gym Address`} value={this.state.gym_address} onChange={this.handleChange('gym_address')}/>
                                        </div>

                    </DialogContent>
                    <DialogActions>
                    <Button className='submitBtn' onClick={ e => this.submitGym()}>Submit</Button>
                        <Button type='submit' onClick={this.closeDialog} color="primary">
                            Close
            </Button>
                    </DialogActions>
                </Dialog>
    </div>;
    }
  }
  
  export default CreateCompetition;