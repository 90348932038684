export function zeroPad(number, size = 2) {
    let s = String(number);
    while (s.length < size) { s = '0' + s;}
    return s;
  }
  
  // Convert time from miliseconds int to hh:mm:ss.S string
  export default function timeFormat(miliseconds ,timeType) {
    
    
    let remaining;
    if(miliseconds < 0){
      remaining = 0;
    }else{
      remaining = miliseconds / 1000;
    }
    
    
  
    const hh = parseInt( remaining / 3600, 10 );
  
    remaining %= 3600;
  
    const mm = parseInt( remaining / 60, 10 );
    const ss = parseInt( remaining % 60, 10 );
    const S  = parseInt( (miliseconds % 1000) / 10, 10 );
  
    if(timeType == 'display'){
      return `${ zeroPad( mm ) }:${ zeroPad( ss ) }.${ zeroPad( S ) }`;
    }else if(timeType == 'live'){
      return `${  mm  }:${ zeroPad( ss ) }.${ zeroPad( S ).slice(0,1) }`;
    }else if(timeType == 'reset'){
      return `${ zeroPad( ss ) }`;
    }else{
      return `${ zeroPad( hh ) }:${ zeroPad( mm ) }:${ zeroPad( ss ) }.${ zeroPad( S ) }`;
    }
  }

  export function convertMilliseconds(mm ,ss, ms){
    var milli = (parseInt(mm || 0)  * 60000) + (parseInt(ss || 0) * 1000) + parseInt(ms || 0);
    return milli;
  }