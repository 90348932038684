import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';
import '../style/ath_hist.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {getAthleteHistory } from '../api/api';
import NSC_Logo from '../static/NSC-Blue-PNG-Outline.png';
import { getLatestMessage } from '../api/api';
import MainWebSocket from "../components/WebSocket";


library.add(faCircle, faTimes);

function getDefaultState() {
  return {
    ws: null,
    comp_id: '',
    athlete_history: [],
    athlete_name: ''
  }
}

class AthleteHistory extends Component {

  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.timerRef = null;
  }

  updateGraphics = (message) =>{
    var parseData = JSON.parse(message.slice(0, -1));
    if (parseData.clock_status == "warming") {
          getAthleteHistory(parseData.athlete_id).then(resp=>{
            console.log(resp);
            var athlete_fullname;
            if(resp.athlete_history.length >0){
                athlete_fullname = resp.athlete_history[0].full_name;
            }
            this.setState({ athlete_history: resp.athlete_history, athlete_name: athlete_fullname });
          });
  }
  }

  componentDidMount() {
    console.log(this);
  }

  render() {
    if(this.props.compId == ""){
      return (<div className="mainDiv" style={{height: "100vh"}}>
          
          <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
              <div className="pulseImg">

              </div>
              </div>
              </div>
      )
  }else{
    return (
      <div>
<MainWebSocket compId={this.props.compId} updateGraphics={this.updateGraphics} getMsgParam="warming"></MainWebSocket>
<div>
        </div>
{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx       
Athlete Result Section
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

<div className="graphicContainer">

{this.state.athlete_history ? 
<div>
<div className="athHist" >
                  <div style={{ marginTop: "10px" }}>
                    <div style={{  }}>
                      <div className="titleContainer">
                      <div className="AHSubTitle">
                      <div><img alt='NSC_Logo' src={NSC_Logo} width="80" height="auto" /></div>
                        <div>{this.state.athlete_name}</div>
                        <div><img alt='NSC_Logo' src={NSC_Logo} width="80" height="auto" /></div>
                      </div>
                      <div style={{fontSize: "12px", marginLeft: "15px", color: "rgba(4, 38,92, 0.95)"}}>* Past 3 competitions</div>
                      </div>
                    </div>
                  </div>

                  {this.state.course_type != 'overall_results' ?
                    <div style={{ marginTop: "0px" }}>
                      <table className="AthHistTable" cellspacing="5" style={{ width: "100%" }}>
                        <thead className="AthHistHeader">
                          <td colspan="1" style={{ textAlign: "left", paddingLeft: "10px" }}>Competition</td>
                          <td style={{padding: "0px 6px"}}>Overall</td>
                          <td style={{padding: "0px 6px"}}>Hybrid</td>
                          <td style={{padding: "0px 6px"}}>Speed</td>
                          <td style={{padding: "0px 6px"}}>Burnout</td>
                          {/* <td style={{padding: "0px 6px"}}>Open</td> */}
                        </thead>
                        <tbody>
                          {this.state.athlete_history.map((item, index) => {
                              return <tr className="LCathleteBox">
                                <td className="AthHistGymCell">{item.gym_name}</td>
                                <td className="AthHistCell">{item.athlete_rank}</td>
                                <td className="AthHistCell">{item.hybrid_rank}</td>
                                <td className="AthHistCell">{item.speed_rank}</td>
                                <td className="AthHistCell">{item.burnout_rank}</td>
                                {/* <td className="AthHistCell">{item.open_rank}</td> */}

                              </tr>
                          })}
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                  }
                  </div>
</div>

: null
}

</div>

{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
End Athlete Result Section
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
        
        {/* End of Leaderboard Section xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
      </div>
    );
}
  }
}

export default AthleteHistory;