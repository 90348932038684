import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';
import '../style/ath_hist.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {getAthleteHistory } from '../api/api';
import NSC_Logo from '../static/NSC-Blue-PNG-Outline.png';
import { getLatestMessage } from '../api/api';


library.add(faCircle, faTimes);

function getDefaultState() {
  return {
    ws: null,
    comp_id: '',
    athlete_history: [],
    athlete_name: ''
  }
}

class MainWebSocket extends Component {

  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.timerRef = null;
  }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = (compId) => {
    var ws = new WebSocket("wss://7o5i09cjfa.execute-api.us-east-2.amazonaws.com/production?comp_id=" + compId);
    let that = this; // cache the this
    var connectInterval;
    console.log("Connecting...");

    // websocket onopen event listener
    ws.onopen = () => {
       console.log("connected websocket main component");

      this.setState({ ws: ws });
      //   var wsObj = {uid: "0", clock_status: "warming", event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: '', time: "00:00.00", current_obstacle: this.props.obstacleInd, total_obstacles: this.props.obstacleCnt}
      //   this.sendMessage({"action": "clockUpdate", "message": JSON.stringify(wsObj) }); //send to websocket if live


      that.timeout = 250; // reset timer to 250 on open of websocket connection 
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };


    ws.onmessage = evt => {
      // listen to data sent from the websocket server

      const message = evt.data;

      console.log(evt);

      if (evt.data.length > 10) {
        // var parseData = JSON.parse(evt.data.slice(0, -1));
        this.distributeMessage(evt.data);
      }

    }

    // websocket onclose event listener
    ws.onclose = e => {
      if (e.type !== 'close') {
        console.log(
          `Socket is closed. Reconnect will be attempted in ${Math.min(
            10000 / 1000,
            (that.timeout + that.timeout) / 1000
          )} second.`,
          e.reason
        );

        that.timeout = that.timeout + that.timeout; //increment retry interval
        connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
      }
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  sendMessage = (data) => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
    //   console.log(error) // catch error
    }
  }

  closeSocket = () => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.close() //send data to the server
    } catch (error) {
    //   console.log(error) // catch error
    }
  }

  distributeMessage(message){
    // var parseData = JSON.parse(message.slice(0, -1));
    this.props.updateGraphics(message);
  //   if (parseData.clock_status == "warming") {
  //         getAthleteHistory(parseData.athlete_id).then(resp=>{
  //           console.log(resp);
  //           var athlete_fullname;
  //           if(resp.athlete_history.length >0){
  //               athlete_fullname = resp.athlete_history[0].full_name;
  //           }
  //           this.setState({ athlete_history: resp.athlete_history, athlete_name: athlete_fullname });
  //         });
  // }
  }

  getMessage(){
    this.check();
    getLatestMessage().then(resp=>{
      console.log(resp);
      console.log(this.props.getMsgParam);
      var arr = resp.message.Items;
      if(arr.length > 0){
        for(var i=0; i<arr.length; i++){
          if(arr[i].message_id == this.props.getMsgParam){
            this.distributeMessage(arr[i].message);
            break;
          }
        }
      }
    });
  }

  componentWillReceiveProps(nextProps){
    console.log(this.props.compId);
    console.log(nextProps);
    if(nextProps.compId !== "" && nextProps.compId != this.props.compId){
      //make a api call here
      this.connect(nextProps.compId);
    }
   }

  componentDidMount() {
    
    console.log(this);
    
    
    window.addEventListener('beforeunload', (event) => {
      console.log('Unloading...');
      // Cancel the event as stated by the standard.
      event.preventDefault();
      this.closeSocket();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  componentWillUnmount() {
    
    this.closeSocket();
  }

  render() {
    return (
      <div>

<div>
        <button className='nsnBtn' onClick={e => this.getMessage()}>Get Message</button>
        </div>
    
      </div>
    );
  }
}

export default MainWebSocket;