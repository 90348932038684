import React, {Component} from 'react';
import NSNHeader from "../components/header";
import NSNFooter from "../components/footer";
import '../style/main.css';
import '../style/videos.css';
import HeroImg from "../components/HeroImg";
import seasonThree from '../static/season_2_sponsors.png';

class Sponsors extends Component {

  state={

  }
 

  componentDidMount(){

  }

    render() {
      return (
          <div className="mainDiv">
            <NSNHeader title='' link='' linkTitle=''></NSNHeader>
            <div>
                        <HeroImg page="sponsor_image" title="Thank you to all of our sponsors!"></HeroImg>
                </div>

                <div className="rulesContainer">
                <div className="NSCTitle" style={{fontSize: "20px"}}>NSC Sponsors</div>
                    <p>NSC is proud to partner with all of our sponsors to bring high level competitions and live streams to the ninja community. NSC would not be possible without the help of all of our sponsors, check out their websites and support our sponsors!</p>
                    </div>

                    <div>
                <div><img src={seasonThree} width="100%"></img></div>
                </div>
              <NSNFooter title='' link='' linkTitle=''></NSNFooter>
          </div>
      );
  }
}

export default Sponsors;
