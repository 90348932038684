import React, { Component } from 'react';
import '../style/main.css';
import '../style/athlete_rankings.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import '../style/graphic_leaderboard.css';
import { getPowerRanking, getAthleteIdFromName } from '../api/api';



library.add(faUser, faCaretDown, faCaretUp);

class NSCAthleteRanking extends Component {

    state = {
        athlete_rank_arr: [],
        rank_gender: 'M',
        activeTab: 'summary',
        last_updated: ''
    }


    changeTabs(val) {
        this.setState({
            activeTab: val,
        });
    };

    goTo(str){
        //   this.props.history.push({
        //       pathname: '/athlete_bio',
        //       search: '?athlete_id=' + id
        //   })
        var first_name = str.substring(0, str.indexOf(' '));
        var last_name = str.substring(str.indexOf(' ') + 1);
        

        getAthleteIdFromName(first_name, last_name).then(resp =>{

            var id = resp.id[0].athlete_id;
            window.open('/athlete_bio?athlete_id=' + id, "_blank"); 
        });

          
    }

    changeGender(val) {
        this.setState({
            rank_gender: val,
        });
    };

    componentDidMount() {
        getPowerRanking().then(resp =>{
            this.setState({athlete_rank_arr: resp.power_ranking, last_updated: resp.power_ranking[0].calcdate || null});
        });
    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
                    <div>
                        <HeroImg page="nsc_athlete_reanking" title="NSC Athlete Ranking"></HeroImg>
                    </div>
                    <div className='tabContainer'>
                        <div className="tabRow">
                            <div onClick={e => this.changeTabs('summary')} className={this.state.activeTab == 'summary' ? 'tabTitle tabTitleActive' : 'tabTitle'}>
                                Summary
                            </div>
                            <div onClick={e => this.changeTabs('formula')} className={this.state.activeTab == 'formula' ? 'tabTitle tabTitleActive' : 'tabTitle'}>
                                Formula
                            </div>
                            <div onClick={e => this.changeTabs('comps')} className={this.state.activeTab == 'comps' ? 'tabTitle tabTitleActive' : 'tabTitle'}>
                                Competitions
                            </div>
                        </div>
                        <div className="tabContent">
                            {this.state.activeTab == 'summary' ? <div>The NSC Power Ranking determines the run order for NSC competitions. As a professional sport we believe that athletes who perform well deserve the advantage of running later in a competition and new athletes can earn their way higher up the power ranking by performing well. The ranking is based on a formula derived from other sports that are similar to Ninja in that athletes compete as individuals, courses are different and can play to different athletes strengths, and the level of competition can vary depending on the competition. For a full breakdown of the formula used, please go to the Formula tab.</div>
                            : this.state.activeTab == 'formula' ? <div>
                                <div><strong>How The Ranking System Works</strong></div>
                                <div style={{margin: "10px 0px"}}>Eligible elite division competitions from the past 3 years are included in the <strong>Ninja Sport Ranking (NSR)</strong>. Youth and Master division competitions are not included.</div>

                                <div style={{margin: "10px 0px"}}>The NSR system is run over a three-year period.</div>

                                <div style={{margin: "10px 0px"}}>Each eligible competition has a calculated <strong>Strength of Field</strong>, which is a point value derived from the ranking of the field of athletes competing.</div>

                                <div style={{margin: "10px 0px"}}>Athletes competing in eligible competitions will receive <strong>Ranking Points</strong> subject to their respective finishing position at the end of the competition, as well as the <strong>Strength of Field</strong> value of the competition</div>

                                <div style={{margin: "10px 0px"}}><strong>Ranking Points</strong> and <strong>Competition Value</strong> are maintained at full value for a 6-month period to place additional emphasis on recent performances. They are then reduced in equal decrements for the remaining 18 months until competitions are no longer counted, after 2 years (24 months).</div>

                                <div style={{margin: "10px 0px"}}>Each athlete is then ranked according to their <strong>Adjusted Average Points (AAP)</strong>, which is determined by dividing the sum of <strong>Ranking Points</strong> by the sum of <strong>Competition Value</strong> from the athlete’s eligible competitions over the three-year period.</div>

                                <div style={{margin: "10px 0px"}}>There is a minimum divisor of 3 applied to the competitions that the athlete has attended over the three-year ranking period.</div>
                            </div>
                            : <div>NSC uses publicly available data from some of the bigger competitions happening in the sport to contribute to each athletes ranking.
                            <ul>
                            <li>All NSC Competitions</li>
                            
                            <li>WNL Finals Seasons 5, 6, 8, 9</li>

                            <li>UNAA 2023 & 2024 Finals</li>

                            <li>FINA 2023 & 2024 Finals</li>
                            
                            <li>Bucket of Chalk 1, 2, 3, and 4</li>
 
                            <li>USA World Cup Demo</li>

                            <li>World Obstacle | Ninja World Cup USA Jul 2023 </li>
                            
                            <li>USANA | US Nationals 2024 </li>

                            <li>*ANW Data is not included due to lack of public availability</li>

                            </ul>
                            </div>}
                        </div>
                    </div>
                    <div className="tableContainer">
                        
                {this.state.last_updated.length > 0 ?
                <div className="updatedTitle">
                    Last Updated: {this.state.last_updated}
                </div>
                    : null}

                {this.state.athlete_rank_arr.length > 0 ?
                    <div>
                                <div className="rankingTitleBox">
                                    <div style={{ textAlign: "center" }}>
                                        <div className="rankingTitle">
                                        <span style={{marginRight: "10px"}}>Ninja Sport Ranking | </span><span  onClick={e => this.changeGender('M')} className={this.state.rank_gender == 'M' ? 'tabTitleGen tabTitleActive' : 'tabTitleGen'}>Men</span>  <span onClick={e => this.changeGender('F')} className={this.state.rank_gender == 'F' ? 'tabTitleGen tabTitleActive' : 'tabTitleGen'}>Women</span>
                                        </div>
                                    </div>
                                </div>
                                        <div style={{ marginTop: "10px", display: "flex" }}>
                                            <div style={{ flex: "1", margin: "0px 0px" }}>
                                                <table className="rankingTable" cellspacing="5" style={{ width: "100%" }}>
                                                <thead className="rankingTableHeader">
                                                            <th>Rank</th>
                                                            <th style={{ textAlign: "left" }}>Athlete</th>
                                                            <th>AAP</th>
                                                            <th>Prev. AAP</th>
                                                            <th>+ / -</th>
                                                        </thead>
                                                    <tbody>
                                                        {this.state.athlete_rank_arr.filter(gen=>gen.athletegender==this.state.rank_gender).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                                                            if (item.athletegender == this.state.rank_gender) {
                                                                return <tr className="athleteBox" onClick={e=>this.goTo(item.athletename)}>
                                                                    <td className="rankCell">{item.athlete_rank}</td>
                                                                    <td className="nameCell" style={{ paddingLeft: "20px" }}>
                                                                        {item.image == "" || item.image == undefined ? <FontAwesomeIcon style={{marginRight: '10px', color: "rgb(160,160,160)", fontSize: "50px"}} icon="user"></FontAwesomeIcon> : null}{item.athletename}
                                                                    </td>
                                                                    <td className="locCell">{item.adjpts}</td>
                                                                    <td className="locCell">{item.prev_points}</td>
                                                                    <td className="locCell">{item.rank_change < 0 ? <FontAwesomeIcon style={{color: '#198754'}} icon="caret-up"></FontAwesomeIcon> : item.rank_change == 0 ? null : <FontAwesomeIcon style={{color: '#dc3545'}} icon="caret-down"></FontAwesomeIcon>} {Math.abs(item.rank_change)}</td>
                                                                </tr>
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>

                                                <div className="rankingMobileTable">
                                                {this.state.athlete_rank_arr.filter(gen=>gen.athletegender==this.state.rank_gender).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                                                            if (item.athletegender == this.state.rank_gender) {
                                                                return <div className="responsiveContainer" onClick={e=>this.goTo(item.athletename)}>
                                                                    <div className="mobileNameRow">
                                                                        <div className="mobileRankCell" style={{textAlign: 'center'}}>{item.athlete_rank}</div>
                                                                        <div className="mobileNameCell" style={{flexGrow: "1", paddingLeft: "10px", height: "50px"}}>{item.image == "" ? <FontAwesomeIcon style={{marginRight: '10px', color: "rgb(160,160,160)", fontSize: "50px"}} icon="user"></FontAwesomeIcon> : null}{item.athletename}</div>
                                                                    </div>
                                                                    <div className="mobileDataRow">
                                                                        <div className="mobileTitleCell" style={{width: "45px"}}>Points:</div>
                                                                        <div className="mobileDataCell">{item.adjpts}</div>
                                                                        <div className="mobileTitleCell">Prev. Points:</div>
                                                                        <div className="mobileDataCell">{item.prev_points}</div>
                                                                        <div className="mobileTitleCell">+ / -</div>
                                                                        <div className="mobileDataCell">{item.rank_change < 0 ? <FontAwesomeIcon style={{color: '#198754'}} icon="caret-up"></FontAwesomeIcon> : item.rank_change == 0 ? null : <FontAwesomeIcon style={{color: '#dc3545'}} icon="caret-down"></FontAwesomeIcon>} {Math.abs(item.rank_change)}</div>
                                                                    </div>
                                                                    </div>
                                                            }
                                                        })}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                    : <div className="legend" style={{margin: "20px"}}>Data is not available at this time</div>}
                    </div>
                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}


export default NSCAthleteRanking;