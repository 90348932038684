import React, { Component } from 'react';
import '../style/main.css';
// import Logo from '../static/nsn_logo.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';
// import { connect } from 'react-redux';
// import { withRouter, Route, Link } from 'react-router-dom';



library.add(faFacebookSquare, faInstagram, faYoutube);
// import Button from '@material-ui/core/Button';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class NSNFooter extends Component { 

    

    render() {
        return (
            <div>
                <div className="footerSection">
                     {/* <div className="linkDiv">
                        <Link className="footerLink" to='/help'>Help</Link>
                    </div> */}
                    {/*<div className="linkDiv">
                        <a className="footerLink" href="javascript:void(0)">FAQ</a>
                    </div> */}
                    {/* <div className="linkDiv">
                        <a className="footerLink" href="https://unx-now-main-storage.s3.amazonaws.com/legal/UNX+Privacy+Policy.pdf" target="_blank">Privacy Policy</a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://unx-now-main-storage.s3.amazonaws.com/legal/UNX+Terms+of+Use.pdf" target="_blank">Terms of Use</a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://unx-now-main-storage.s3.amazonaws.com/legal/UNX+Cookie+Policy.pdf" target="_blank">Cookie Policy</a>
                    </div> */}
                    {/* <div className="linkDiv">
                        <a className="footerLink" href="javascript:void(0)">Stats</a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="javascript:void(0)">Compete</a>
                    </div> */}
                    <div className="linkDiv">
                        <a className="footerLink" href="https://www.youtube.com/channel/UCeidwTomRFqkERADxH2iYpA" target="_blank" style={{fontSize: "2.5em"}}><FontAwesomeIcon icon={['fab', 'youtube']}/></a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://www.instagram.com/ninjasportnetwork/" target="_blank" style={{fontSize: "2.5em"}}><FontAwesomeIcon icon={['fab', 'instagram']}/></a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://www.facebook.com/ninjasportnetwork" target="_blank" style={{fontSize: "2.5em"}}><FontAwesomeIcon icon={['fab', 'facebook-square']}/></a>
                    </div>
                    Ninja Sport Network
                </div>
            </div>
        );
    }
}

// const mapStateToProps = state => ({
//     auth: state.auth
//   });

// const mapDispatchToProps = {
//     logoutUserFromReduxState
//    };


// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NSNFooter));

export default NSNFooter;