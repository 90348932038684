import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';

import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'



//************************************************ */
//Persited Store configuration 
//************************************************ */

import rootReducer from './reducers'
 
const persistConfig = {
  key: 'unx-amplify-root',
  // storage,
  storage: new CookieStorage(Cookies, {
    expiration: {
      'default': 86400 // = 24 hours
      // default: null // uses session cookies
    }
  })
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, applyMiddleware(reduxThunk));
export const persistor = persistStore(store);
