import { AUTH_USER, UNAUTH_USER, SET_USER, UPDATE_USER } from './action_types';


export function logoutUserFromReduxState(){
	// console.log("Redux State Called Logout");
	return function(dispatch){
		dispatch({
			type: UNAUTH_USER
		})
	}
}

export function setUserToReduxState(user){
	// console.log("Redux State Called Set");
	return function(dispatch){
		dispatch({
			type: AUTH_USER
		})
		dispatch({
			type: SET_USER,
			payload: user
		})
		// redirect to route
		// this.props.history.push('/home')
	}
}


export function updateUserReduxState(user){
	// console.log("Redux State Called Update");
	// console.log(user);
	return function(dispatch){
		dispatch({
			type: UPDATE_USER,
			payload: user
		})
		// redirect to route
		// this.props.history.push('/home')
	}
}