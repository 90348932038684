import React, {Component} from 'react';
import awsconfig from './../aws-exports'; // if you are using Amplify CLI
import Amplify, { API } from 'aws-amplify';
import {getUser} from '../api/aws/amplify_api';
import { connect } from 'react-redux';
import { verifyUser} from '../functions/auth_functions';
import {setUserToReduxState, logoutUserFromReduxState} from '../actions/auth_actions';
import Form from '../functions/authForm';
import NSNHeader from "../components/header";
import '../style/main.css';

Amplify.configure(awsconfig);

class SignInPage extends Component {

  goToSub(){
      this.props.history.push('/dashboard');
  }

  formCallback = (data) =>{
    getUser().then(resp => {
      console.log(resp);
      var reduxObj = {id: resp.attributes.id, accessExp: data.user.signInUserSession.accessToken.payload.exp};
      this.props.setUserToReduxState(reduxObj);
      this.props.history.push('/results_entry');
    }).catch(err=>{
        console.log(err);
    });
  }

  componentWillMount(){
      console.log('test');
    verifyUser(this.props.auth).then(resp=>{
      this.props.history.push('/results_entry');
    }).catch(err=>{
        this.props.logoutUserFromReduxState();
    });
  }

    render() {
      return (
          <div className="mainDiv">
            <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>
            <div className="pageContainer">
            <div className="pageTitle">Sign in or create an account!</div>
            <div>
              <Form signInCallback={this.formCallback}/>
            </div>
            </div>
          </div>
      );
    }
  }

const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
    setUserToReduxState,
    logoutUserFromReduxState
   };

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
