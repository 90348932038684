import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';

import {store} from '../store';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return(
    <Route {...rest} render={(props) => (
      store.getState().auth.authenticated == false ? <Redirect to='/sign_in' />
        : <Component {...props} />
    )} />
  )
}


export default PrivateRoute;