import React, { Component } from 'react';
import '../style/stopwatch.css';
import '../style/live_clock.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getRunOrder_LeaderBoard, getAthleteResult } from '../api/api';
import NSC_Logo from '../static/NSC-Blue-PNG-Outline.png';
import { getLatestMessage } from '../api/api';


library.add(faCircle, faTimes);

function getDefaultState() {
  return {
    ws: null,
    comp_id: '',
    results: [],
    course_type: 'open_results',
    result_type: 'leaderboard',
    scoreboard_gender: '',
    overall_results: [],
    athlete_results: [],
    athlete_name: ''
  }
}

class PostRunScore extends Component {

  constructor(props) {
    super(props);
    this.state = getDefaultState();
    this.timerRef = null;
  }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = (compId) => {
    var ws = new WebSocket("wss://7o5i09cjfa.execute-api.us-east-2.amazonaws.com/production?comp_id=" + compId);
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
    //   console.log("connected websocket main component");

      this.setState({ ws: ws });
      //   var wsObj = {uid: "0", clock_status: "warming", event_id: this.props.event_id, athlete: this.props.athlete_name, gender: this.props.gender, obstacle_result: '', time: "00:00.00", current_obstacle: this.props.obstacleInd, total_obstacles: this.props.obstacleCnt}
      //   this.sendMessage({"action": "clockUpdate", "message": JSON.stringify(wsObj) }); //send to websocket if live


      that.timeout = 250; // reset timer to 250 on open of websocket connection 
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };


    ws.onmessage = evt => {
      // listen to data sent from the websocket server

      const message = evt.data;

      console.log(evt);

      if (evt.data.length > 10) {
        // var parseData = JSON.parse(evt.data.slice(0, -1));
        this.updateGraphics(evt.data);
      }

    }

    // websocket onclose event listener
    ws.onclose = e => {
      if (e.type !== 'close') {
        console.log(
          `Socket is closed. Reconnect will be attempted in ${Math.min(
            10000 / 1000,
            (that.timeout + that.timeout) / 1000
          )} second.`,
          e.reason
        );

        that.timeout = that.timeout + that.timeout; //increment retry interval
        connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
      }
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  sendMessage = (data) => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.send(JSON.stringify(data)) //send data to the server
    } catch (error) {
    //   console.log(error) // catch error
    }
  }

  closeSocket = () => {
    const { ws } = this.state // websocket instance passed as props to the child component.
    // console.log(data);
    try {
      ws.close() //send data to the server
    } catch (error) {
    //   console.log(error) // catch error
    }
  }

  updateGraphics(message){
    var parseData = JSON.parse(message.slice(0, -1));
    if (parseData.clock_status == "athlete_submitted") {
      var courseDesc;
      switch (parseData.result_table) {
        case 'open_results':
          courseDesc = 'Open Round';
          break;
        case 'speed_results':
          courseDesc = 'Speed Course';
          break;
        case 'hybrid_results':
          courseDesc = 'Hybrid Course';
          break;
        case 'burnout_results':
          courseDesc = 'Burnout Course';
          break;

      }
      this.setState({
        course_desc: courseDesc
      });

          getAthleteResult(parseData.event_id, parseData.result_table, parseData.athlete_id).then(resp3=>{
            console.log(resp3);
            this.setState({ comp_id: parseData.event_id, course_type: parseData.result_table, result_type: 'leaderboard', scoreboard_gender: parseData.gender, athlete_results: resp3, athlete_name: parseData.athlete });
          });
  }
  }

  getMessage(){
    getLatestMessage().then(resp=>{
      console.log(resp);
      var arr = resp.message.Items;
      if(arr.length > 0){
        for(var i=0; i<arr.length; i++){
          if(arr[i].message_id == 'athlete_submitted'){
            this.updateGraphics(arr[i].message);
            break;
          }
        }
      }
    });
  }

  componentWillReceiveProps(nextProps){
    console.log(this.props.compId);
    console.log(nextProps);
    if(nextProps.compId !== ""){
      //make a api call here
      this.connect(nextProps.compId);
    }
   }


  componentDidMount() {
    console.log(this);
    // this.connect();
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      this.closeSocket();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });
  }

  componentWillUnmount() {
    this.closeSocket();
  }

  render() {
    return (
      <div>

<div>
        <button className='nsnBtn' onClick={e => this.getMessage()}>Get Message</button>
        </div>
{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx       
Athlete Result Section
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

<div>

{this.state.athlete_results.course_results ? 
<div>
<table className="liveClockTable" cellSpacing="0" style={{ borderSpacing: "0px" }}>
            <tbody className="LCBody">
              <tr>
                <td className="RescourseBox" style={{ textAlign: 'center' }}>Result</td>
              </tr>
              <tr className="LCNameRow">
                <td className="V2LCLogoBox" style={{height: 'auto'}}><img alt='NSC_Logo' src={NSC_Logo} width="90" height="auto" /></td>
                <td className="LCtextTitle V2AthleteBox" style={{ textAlign: 'center', height: "50px" }}>{this.state.athlete_name}</td>
                
              </tr>
              <tr style={{color: "#000000"}}>
                <td className="RescourseBox" style={{ textAlign: 'center', fontSize: "18px", color: "#000000",  background: "linear-gradient(rgb(59, 129, 42), rgb(102, 216, 73),rgb(56, 122, 40))" }}>{this.state.course_type.substring(0, this.state.course_type.indexOf('_'))}</td>
                <td className="resTDBox" style={{background: "linear-gradient(rgb(59, 129, 42), rgb(102, 216, 73),rgb(56, 122, 40))"}}>
                {this.state.athlete_results.course_results.length > 0 ? 
                <div className="resOvrContain">
                    <div className="resOvrStatContain">
                      <div className="resOvrStatTitle" style={{color: "#000000"}}>
                        Rank
                      </div>
                      <div className="resOvrStat" style={{color: "#000000"}}>
                        {this.state.athlete_results.course_results[0].athlete_rank}
                      </div>
                    </div>
                    <div className="resOvrStatContain">
                      <div className="resOvrStatTitle" style={{color: "#000000"}}>
                        OBS
                      </div>
                      <div className="resOvrStat" style={{color: "#000000"}}>
                        {this.state.athlete_results.course_results[0].points}
                      </div>
                    </div>
                    <div className="resOvrStatContain">
                      <div className="resOvrStatTitle" style={{color: "#000000"}}>
                        TF
                      </div>
                      <div className="resOvrStat" style={{color: "#000000"}}>
                        {this.state.athlete_results.course_results[0].total_fails}
                      </div>
                    </div>
                    <div className="resOvrStatContain">
                      <div className="resOvrStatTitle" style={{color: "#000000"}}>
                        Time
                      </div>
                      <div className="resOvrStat" style={{color: "#000000"}}>
                        {this.state.athlete_results.course_results[0].tiebreak_time.substr(3)}
                      </div>
                    </div>
                  </div>
                  : null}
                </td>
              </tr>
              {this.state.athlete_results.overall_results.length > 0 ? <tr>
                <td className="RescourseBox" style={{ textAlign: 'center' , fontSize: "18px" }}>Overall</td>
                <td className="resTDBox" style={{ padding: "0px;" }}>
                {this.state.athlete_results.overall_results.length > 0 ? 
                  <div className="resOvrContain">
                    <div className="resOvrStatContain">
                      <div className="resOvrStatTitle">
                        Rank
                      </div>
                      <div className="resOvrStat">
                        {this.state.athlete_results.overall_results[0].overall_rank}
                      </div>
                    </div>
                    <div className="resOvrStatContain">
                      <div className="resOvrStatTitle">
                        Points
                      </div>
                      <div className="resOvrStat">
                        {this.state.athlete_results.overall_results[0].points}
                      </div>
                    </div>
                  </div>
                  :null}
                </td>
              </tr>:null}
            </tbody>
          </table>

</div>

: <div>
<div>
<table className="liveClockTable" cellSpacing="0" style={{ borderSpacing: "0px" }}>
            <tbody className="LCBody">
              <tr>
                <td className="courseBox" style={{ textAlign: 'center' }}>Result</td>
              </tr>
              <tr className="LCNameRow">
                <td className="LCLogoBox"><img alt='NSC_Logo' src={NSC_Logo} width="90" height="auto" /></td>
                <td className="LCtextTitle LCNameRow" style={{ textAlign: 'center', height: "50px" }}>{this.state.athlete}</td>
                
              </tr>
              <tr>
                <td className="courseBox" style={{ textAlign: 'center' , fontSize: "18px", color: "var(--nsn-green)", height: "50px" }}>{this.state.course_type.substring(0, this.state.course_type.indexOf('_'))}</td>
                <td className="" style={{ padding: "0px;" }}></td>
              </tr>
              <tr>
                <td className="courseBox" style={{ textAlign: 'center' , fontSize: "18px", height: "50px" }}>Overall</td>
                <td className="" style={{ padding: "0px;" }}></td>
              </tr>
            </tbody>
          </table>

</div>
</div>
}

</div>

{/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
End Athlete Result Section
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
        
        {/* End of Leaderboard Section xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
      </div>
    );
  }
}

export default PostRunScore;